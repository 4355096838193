import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import qs from 'qs';

import { getToken, setToken, eraseToken, eraseRefreshToken, getRefreshToken, setRefreshToken, getCookie } from '../Utils/cookie';
import { getClientCredential } from '../Utils/getClientService';
import {
    updateModalsOpen,
    updateModalsLoginType,
    updateUserUuid,
    updateUserIupid,
    updateUserPseudo,
    updateUserDateInscription
} from '../Store/action';
import { useSafeState } from './useSafeState';


/**
 * @async Creation d'un hook d'appel des API avec oAuth Anonyme
 * @param {string} name : nom de l'API
 * @param {string} _uid : id de la page (route)
 * @param {string} include : données à inclure dans le résultat de la requete
 * @param {string} exclude : données à exclure dans le résultat de la requete
 * @param {string} fields : champs à récupérer
 * @param {string} filter : filtre des résultats
 * @param {string} sort : ordonnancement du résultat
 * @param {string} limit : limitation du nombre de résultat
 * @param {string} path : url de la page (decoupled router)
 * @param {string} method : post / get
 * @param {string} data : données à envoyer en parametres
 */

const baseURL = process.env.REACT_APP_APP;

// Whether the mark is being refreshed
let isRefreshing = false;

// Retry queue, each item will be a form of function to be executed
let retryRequests = [];

let UserModule = {
    RefreshToken: (data) => {
        setToken(data.access_token);
        if (data.refresh_token) {
            setRefreshToken(data.refresh_token)
        }
    }
};

const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_APP,
    timeout: 180000
});

let uuidStore = '';

axiosInstance.interceptors.request.use(
    (config) => {
        log("DEBUG - Interception Requete - OK", config.url);
        const token = getToken();
        if (token && token !== "Init") {
            log("DEBUG - Interception Requete - Presence Token / Ajout Headers Config", config.url);
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return config
    },
    (error) => {
        log("DEBUG - Interception Requete - Erreur");
        Promise.reject(error)
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // on renvoi le JSON
        log("DEBUG - Interception Response - OK", response.config.url);
        return response;
    },
    (error) => {
        log("DEBUG - Interception Response - Erreur", error.response.config.url);

        if (!error.response) {
            log("DEBUG - Interception Response - Erreur - Pas de Reponse", error.response.config.url);

            if (error.code === 'ECONNABORTED') {
                return 'timeout';
            } else {
                return Promise.reject(error);
            }
        }

        log("DEBUG - Interception Response - Erreur - 401/403", error.response.config.url);

        log("***************************")
        log("PILE REQUETE", error.response.config.pileRequete)
        log("***************************")

        if ((error.response.status === 401 || error.response.status === 403) && !error.response.config.pileRequete) {
            log("DEBUG - Interception Response - Erreur - 401/403", error.response.config.url);

            const config = error.config;
            log2("G - Token périmé ! ");

            // Add attempt request mechanism
            // Request give up after 4 attempts
            if (config.headers['AttemptNumber'] > 4) {
                log("DEBUG - Interception Response - Erreur - 401/403 - ESSAI > 3", error.response.config.url);
                return Promise.reject(error);
            }

            if (!isRefreshing) {
                log("DEBUG - Interception Response - Erreur - 401/403 - PAS REFRESHING", error.response.config.url);

                isRefreshing = true;
                log2("G - On rafraichit le Token ");

                if (uuidStore) {
                    log("***************************")
                    log("UUID", uuidStore)
                    log("***************************")
                }

                return ActualiseToken(uuidStore, error, config, true);

            } else {

                log("DEBUG - Interception Response - Erreur - 401/403 - DEJA REFRESHING - EMPILAGE REQUETE", error.response.config.url);

                // Refreshing the token, returning a promise that has not been resolved
                log2("G - Le token a été rafraichit on relance les requetes");
                return new Promise((resolve) => {

                    // Put resolve into the queue, save it in a function form, and execute directly after the token is refreshed
                    // @ts-ignore
                    retryRequests.push((token) => {
                        config.headers['Authorization'] = 'Bearer ' + token;
                        config.headers['AttemptNumber'] = config.headers['AttemptNumber'] ? config.headers['AttemptNumber'] + 1 : 1;
                        config.pileRequete = true;

                        resolve(axiosInstance(config));
                    })
                })
            }
        }
        else {
            return Promise.reject(error);
        }
    }
);

function log() {
    //console.log(...arguments);
}

function log2() {
    //console.log(...arguments);
}

function ActualiseToken(uuidStore, error, config, isFirst) {
    return getRefreshTokenFunc(uuidStore, error)
        .then((res) => {
            log2('G - Le rafraichissement du token est ok');
            log("DEBUG - Interception Response - Erreur - 401/403 - RECUPERATION NOUVEAU TOKEN", error.response.config.url);

            // reset token
            UserModule.RefreshToken(res.data);
            config.headers['Authorization'] = 'Bearer ' + getToken();

            // The token has been refreshed, retry all the requests in the queue
            // @ts-ignore
            log2('G - Rafraichissement de token ok - pile de requete', retryRequests);

            log("DEBUG - Interception Response - Erreur - 401/403 - EXECUTION REQUETES EMPILEES", error.response.config.url);
            retryRequests.forEach((cb) => {
                cb(getToken())
            })

            // Empty the queue after retrying
            retryRequests = [];
            isRefreshing = false;

            config.headers['AttemptNumber'] = config.headers['AttemptNumber'] ? config.headers['AttemptNumber'] + 1 : 1;

            log("DEBUG - Interception Response - Erreur - 401/403 - EXECUTION REQUETE INITIALE", error.response.config.url);
            config.pileRequete = true;
            return axiosInstance(config);
        })
        .catch((err) => {
            log("DEBUG - Interception Response - Erreur - 401/403 - ERREUR DE RECUPERATION DE NOUVEAU TOKEN - EFFACAGE DES TOKENS", error.response.config.url, err);
            eraseToken();
            eraseRefreshToken();

            if (isFirst) {
                return ActualiseToken(uuidStore, error, config, false);
            } else {
                isRefreshing = false;
                return Promise.reject(err);
            }
        })
        .finally(() => {
            log("DEBUG - Interception Response - Erreur - 401/403 - FINALLY", error.response.config.url);
            return error.response;
        })
}


function getRefreshTokenFunc(uuid, error) {
    log("DEBUG - getRefreshTokenFunc", error.response.config.url);

    let refresh = getRefreshToken();
    const token = getToken();
    if (!uuid || uuid === "" || token === "Init" || !token) {
        log("DEBUG - getRefreshTokenFunc - TOKEN ABSENT", error.response.config.url);
        log2(uuid);
        refresh = "";
        setRefreshToken("");
    }

    log("DEBUG - getRefreshTokenFunc - CREATION REQUETE RECUPERATION TOKEN", refresh, error.response.config.url);

    return Axios({
        method: 'post',
        url: baseURL + '/oauth/token',
        data: qs.stringify(
            getClientCredential(
                refresh,
                (refresh !== "undefined" && refresh !== null && refresh !== "") ? "refresh_token" : "client_credentials"
            )
        )
    })
}

function unlog(dispatch, modalsOpen) {
    dispatch(updateUserUuid(""));
    dispatch(updateUserIupid(""));
    dispatch(updateUserPseudo(""));
    dispatch(updateUserDateInscription(""));
    dispatch(updateModalsLoginType('login'));
}


function* isCurrentPage(routes = []) {
    if (routes.length > 0) {
        for (const route of routes) {
            if (route && route.path && window.location.pathname.includes(route.path)) {
                yield route
            }
        }
    }
}


export default ({ name, _uid = "", grant_type, include, exclude = "", fields = "", filter = "", sort = "", limit = "", path, method = "post", data = "", consumerId = "", format = "", noData = false, pagination = "", config = {}, recaptcha_token = "", needLogin = false, wantResponse = false, uuid = "" }, props = []) => {
    const dispatch = useDispatch();
    const [dataFetch, setDataFetch] = useSafeState();
    const [isLoaded, setIsLoaded] = useSafeState(false);
    const global = useSelector((state) => state.global);
    uuidStore = useSelector(state => state.user).uuid;
    const modalsOpen = useSelector(state => state.modals).openModal;

    const route = isCurrentPage(global.routes).next().value;

    const currentUrl = window.location.href;

    let srcid = getCookie('srcid');
    let srcid_auth_header_key = getCookie('srcid_auth_header_key');
    if (srcid && srcid_auth_header_key) {
        config[srcid_auth_header_key] = srcid;
    }

    useEffect(() => {
        if (route && (route.require_login || currentUrl.indexOf("show_login") > -1) && !uuidStore) {
            dispatch(updateModalsOpen({ 'login': true }));
        }
    }, [dispatch, route]);

    useEffect(() => {
        if (name && name !== '') {

            let delay = 0;
            if (name.indexOf("api/lba-economy/selection/brand") > -1 || name.indexOf("api/lba-economy/selection/shelf") > -1 || name.indexOf("api/lba-economy/selection/universe") > -1) {
                delay = 500;
            }

            let load = false; // eslint-disable-line
            let refreshTokenInitial = getRefreshToken();
            setIsLoaded(false);

            const fetchAPi = async () => {
                try {
                    let urllp = getCookie('urllp');
                    if(urllp){
                        if(data?.data?.attributes){
                            data.data.attributes.lba_urllp = urllp
                        }
                    }

                    const dataFetchRequest = await axiosInstance({ // eslint-disable-line
                        method: method,
                        url: `/${name}${_uid ? `/${_uid}` : ''}${(format || recaptcha_token || include || exclude || fields || filter || sort || limit || path || consumerId) ? '?' : ''}${format ? `_format=${format}` : ''}${recaptcha_token ? `&recaptcha_token=${recaptcha_token}` : ''}${include ? `&include=${include}` : ''}${exclude ? `&exclude=${exclude}` : ''}${fields && fields}${filter && filter}${sort && sort}${limit && `&page[limit]=${limit}`}${pagination && pagination}${path ? '&path=' + path : ''}${consumerId ? '&consumerId=' + consumerId : ''}`,
                        data: !noData ? data : '',
                        headers: config ? config : ''
                    })
                        .then(response => {

                            if (delay > 0) {
                                setTimeout(() => {
                                    setDataFetch(response);
                                    log("DEFAULT", needLogin)
                                    let refreshTokenFinal = getRefreshToken();

                                    if (refreshTokenFinal === "" && refreshTokenInitial !== "") {
                                        log("DEFAULT BIS", needLogin)
                                        unlog(dispatch, modalsOpen);
                                    }
                                }, delay);
                            } else {
                                setDataFetch(response);
                                log("DEFAULT", needLogin)
                                let refreshTokenFinal = getRefreshToken();

                                if (refreshTokenFinal === "" && refreshTokenInitial !== "") {
                                    log("DEFAULT BIS", needLogin)
                                    unlog(dispatch, modalsOpen);
                                }
                            }


                        }).catch(err => {
                            setDataFetch(err.response);
                            log("DEFAULT 2", needLogin, err.response)

                            // If error message require connection. Make connection popIn visible.
                            if (err.response.data.message && (err.response.data.message.includes("permission is required") || err.response.data.message.includes("The refresh token is invalid"))) {
                                log("DEFAULT 3", needLogin, err.response)
                                unlog(dispatch, modalsOpen);
                            } else if (err.response.data.error_code && (err.response.data.error_code.includes("MEMBER_AUTHENTICATION_ERROR"))) {
                                log("DEFAULT 4", needLogin, err.response)
                                unlog(dispatch, modalsOpen);
                            }
                        });
                    setIsLoaded(true);
                } catch (err) {
                    log("DEFAULT 5", needLogin, err.response)
                    setDataFetch(err.response);
                    setIsLoaded(true);
                    log(err)
                }
            };

            fetchAPi();

            return () => {
                load = true;
            };
        }
    }, [name, fields, include, filter, sort, _uid, path, data, ...props, pagination, uuid]); // eslint-disable-line

    return [dataFetch, isLoaded];
}