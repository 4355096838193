import React, {useEffect, useState, Fragment} from "react";
import {useSelector, useDispatch} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import {useHistory, useLocation} from 'react-router-dom';
import {getMsgError} from '../Utils/getMsgError';

import {getCookie} from '../Utils/cookie';

// Hooks
import {
    updateGlobalStorePage,
    updateModalsOpen,
    updateModalsLoginType,
    updateModalsDataIsLoaded,
    updateModalsRedirectButton
} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';

// Libraries
import {useForm, Controller} from "react-hook-form";
import Axios from "axios";

// Components
import {Loader} from "../Components/loader";
import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import Video from "../Components/article/video";
import WysiwygSimple from "../Components/wysiwygSimple";
import Tableau from '../Components/tableau';
import Cta from "../Components/cta";
import VisuelLegend from "../Components/visuelLegend";
import Steps from "../Components/article/stepText";
import CarouselCashback from "../Components/carouselCashback";
import CLink from "../Components/cLinks";
import {useSafeState} from "../Hooks/useSafeState";

// Utils
import {randomString} from "../Utils/md5-hash";
import CarouselWebcoupon from "../Components/carouselWebcoupon";

const TemplateOperationsPage = ({_uid}) => {
    const baseURL = process.env.REACT_APP_APP;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-operationsPage'));
    }, []); // eslint-disable-line

    const {desktop} = useSelector(state => state.breakpoint);

    const location = useLocation();
    const history = useHistory();

    // Loader
    const [loading, setLoading] = useState(false);
    const [readyToPlay, setReadyToPlay] = useState(true);    // load between welcome page and game page (prevent jumping)

    // Get global info
    // let dataGlobal = useSelector(state => state.global).global;

    // Param for showing loader when login
    const pageDataIsLoaded = useSelector(state => state.modals).dataIsLoaded;

    //Recuperation des infos de la page
    const [dataRedux, setDataRedux] = useState();

    // Register form
    const {register, handleSubmit, errors, setError} = useForm();

    // User info
    let {uuid} = useSelector(state => state.user);
    let [params, setParams] = useState('');
    const [initClient, isLoadedUser] = useApi(params);
    let [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        if (uuid) {
            setParams({name: `api/users/${uuid}`, method: 'get'})
        }
    }, [uuid]);

    // Set user infos
    useEffect(() => {
        if (initClient && isLoadedUser && params) {
            let {status, data} = initClient;
            // console.log(data);
            if (status === 200) {
                //tout est ok
                // console.log("INFO PERSO ", data.data);
                setUserInfo(data.data.attributes);
            }
        }
    }, [initClient, isLoadedUser, params]); // eslint-disable-line

    // Get account edit page url
    const accountEditPath = useSelector(state => state.routes).routes.accountEdit;


    // Recupère les infos
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/operation_page',
        _uid: _uid,
        include: 'field_common_pgph_contents,field_common_pgph_ban.field_respimg_img_desktop,field_common_pgph_ban.field_respimg_img_mobile,field_common_tr_brands,field_common_pgph_contents.paragraph_type,field_common_pgph_contents.field_landing_video,field_common_pgph_contents.field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_contents.field_common_pgph_ban.field_respimg_img_desktop,field_common_pgph_contents.field_landing_pgph_steps,field_common_pgph_contents.field_landing_pgph_steps.field_diy_img_step',
        fields: '&fields[node--operation_page]=metatag,field_common_pgph_contents,field_operations_list_pagetype,field_common_pgph_ban,field_common_tr_brands',
        method: 'get'
    });

    // Normalize les infos
    useEffect(() => {
        if (dataFetch && dataFetch.data) {
            const normalizeJson = normalize(dataFetch.data);
            setDataRedux(build(normalizeJson, 'nodeOperationPage', _uid));
        }

        if (modalsOpen.login) {
            dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'hasError': false}));
        }
        if (!pageDataIsLoaded) {
            dispatch(updateModalsDataIsLoaded(true));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    const [decoupledApi, setDecoupledApi] = useState({});
    const [operationBrands, setOperationBrands] = useState();
    const [errorAvantage, setErrorAvantage] = useState("");

    useEffect(() => {
        if (dataRedux) {
            // console.log('dataRedux', dataRedux);

            // Remove loader in load
            setLoading(false);
            setReadyToPlay(true);

            // Recupère les infos de l'opération
            setDecoupledApi({
                name: 'router/translate-path',
                path: location.pathname,
                method: 'get',
            });

            // Liste des marques
            if (dataRedux.fieldCommonTrBrands && dataRedux.fieldCommonTrBrands.length > 0) {
                let brandsArray = [];
                dataRedux.fieldCommonTrBrands.map((item) => {
                    brandsArray.push(item.name);
                    return null;
                });
                setOperationBrands(brandsArray.join(', '));
            }
        }

        // Check if logged
        if (!uuid && dataRedux && (dataRedux.fieldOperationsListPagetype === 'event' || dataRedux.fieldOperationsListPagetype === 'already_participated' || dataRedux.fieldOperationsListPagetype === 'thanks')) {
            dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'password': false, 'economy': false}));
            dispatch(updateModalsLoginType('operation'));
        }

    }, [dataRedux, uuid]); // eslint-disable-line

    const [dataDecoupled, isLoadedDecoupled] = useApi(decoupledApi);
    const [lbaOperations, setLbaOperations] = useState()



const [paramsUrl, setParamsUrl] = useState(null);
    useEffect(() => {
        // Check URL params
        const url =window.location.href;
        function getAllUrlParams(url) {
            // get query string from url (optional) or window
            let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

            // if query string exists
            if (queryString) {
                // stuff after # is not part of query string, so get rid of it
                queryString = queryString.split('#')[0];
            }

            return queryString;
        }

        setParamsUrl(getAllUrlParams(url));
    }, []);

    useEffect(() => {
        // console.log(dataDecoupled, isLoadedDecoupled)
        if (dataDecoupled && isLoadedDecoupled) {
            if (dataDecoupled.data && dataDecoupled.data.lba_operations) {
                setWithCode(dataDecoupled.data.lba_operations.has_advantage_code)
                // console.log('dataRedux decoupled', dataDecoupled.data);
                if(dataDecoupled.data.lba_operations.redirect_path) {
                    // Redirect to Thanks page
                    history.replace({
                        pathname: `${dataDecoupled.data.lba_operations.redirect_path}`,
                        search: `${paramsUrl ? `?${paramsUrl}` : ''}`});
                }
                setLbaOperations(dataDecoupled.data.lba_operations);
                // Set redirect link for login
                dispatch(updateModalsRedirectButton(`${dataDecoupled.data.lba_operations.operation_link_url}${paramsUrl ? `?${paramsUrl}` : ''}`));

            }
        }
    }, [dataDecoupled, isLoadedDecoupled]);


    // TRACKING
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const typeParticipate = ["", "commande d’échantillon", "jeu concours"];
    const participateTracking = (refresh) => {
        let participation_type="";
        if(dataDecoupled.data.lba_operations.operation_type && typeParticipate.hasOwnProperty(dataDecoupled.data.lba_operations.operation_type)) {
            participation_type = typeParticipate[dataDecoupled.data.lba_operations.operation_type];
        }
        // click_participate
        setTrackingEvent({
            event: 'click_participate',
            args: {
                participation_type: participation_type,
                content_category3: operationBrands,
                content_category2: 'Jeu',
                content_category: dataDecoupled.data.label,
            },
            refresh: refresh
        });
    };

    const participateConfirmTracking = (refresh) => {
        let participation_type="";
        if(dataDecoupled.data.lba_operations.operation_type && typeParticipate.hasOwnProperty(dataDecoupled.data.lba_operations.operation_type)) {
            participation_type = typeParticipate[dataDecoupled.data.lba_operations.operation_type];
        }
        // click_participate_confirmation
        setTrackingEvent({
            event: 'click_participate_confirmation',
            args: {
                participation_type: participation_type,
                content_category3: operationBrands,
                content_category2: 'Jeu',
                content_category: dataDecoupled.data.label,
            },
            refresh: refresh
        });
    };

    // Modals - Login
    const modalsOpen = useSelector(state => state.modals).openModal;

    // Participate button
    const participate = (event) => {
        event.preventDefault();

        // Tracking - click_participate
        participateTracking(randomString(6));
        if (uuid) {
            // Show loader
            setReadyToPlay(false);

            // Redirect to game page
            history.replace({
                pathname: lbaOperations.operation_link_url,
                search: `${paramsUrl ? `?${paramsUrl}` : ''}`
            });

        } else {
            // console.log('NON LOGGUE');
            dispatch(updateModalsOpen({'login': true}));
            dispatch(updateModalsLoginType('operation'));
            setLoading(false);
        }
    }

    const [fileUpload, setFileUpload] = useState();
    // const [fileName, setFileName] = useState("");
    const [dataInfos, setDataInfos] = useState({});
    const [withCode, setWithCode] = useState(false);


    // SEND QUESTION DATA
    const onSubmit = (dataForm) => {

        if('comment' in dataForm && withCode){
            dataForm["comment"] = dataForm["comment"].toUpperCase();
        }
        
        if ('optin' in dataForm) {
            dataForm.optin = parseInt(dataForm.optin);
        }
        dataForm.srcid = getCookie('srcid') ? parseInt(getCookie('srcid')) : '';
        const dataSend = {};
        Object.assign(dataSend, dataForm);

        // Show loader
        setLoading(true);
        setReadyToPlay(false)

        // Récupération du token à passer ds le header
        let fetchJeton = Axios.post(`${baseURL}/session/token/`);

        fetchJeton.then(res => {
            let {status, data} = res;

            if (status === 200) {
                if (dataDecoupled.data.lba_operations.upload.type === "receipt" || dataDecoupled.data.lba_operations.upload.type === "image") {
                    // Requete POST pour recuperer le nom du fichier uploadé
                    sendUpload(data, dataSend)
                    // Mise a jour du dataForm pour post la participation de l'evenement avec upload
                    setDataInfos({
                        data,
                        dataSend
                    })
                } else {
                    SendData(data, dataSend);
                }

            } else {
                console.log('erreur', data);
                // Hide loader
                setLoading(false);
            }
        })
    };

    let [paramsSend, setParamsSend] = useSafeState({})
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        setParamsSend({
            name: 'api/lba-operations/submit/' + lbaOperations.operation_id + '?_format=json',
            method: 'post',
            data: dataSend,
        });
    };

    // HANDLE CHANGE INPUT UPLOADS 
    

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let {status, data} = responseSend;
            if (status === 200) {
                setErrorAvantage("");
                //tout est ok
                // TRACKING
                participateConfirmTracking(randomString(6));
                // Redirect to Thanks page
                history.replace({
                    pathname: data,
                    search: `${paramsUrl ? `?${paramsUrl}` : ''}`
                });
            }
            else {
                //Erreur
                console.log('ERREUR', data.error);
                setErrorAvantage(getMsgError(data.error_code))
                setReadyToPlay(true);
            }

            // Hide loader
            setLoading(false);
        }
    }, [responseSend, isLoadedSend]);

 
     const handleInputUploadsChange = (e) => {
         let validFormats 
         if (dataDecoupled.data.lba_operations.upload.type === "image") {
            validFormats = e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg"
         }
         if (dataDecoupled.data.lba_operations.upload.type === "receipt") {
            validFormats = e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "application/pdf"
         }
         if (validFormats) {
             setFileUpload(e.target.files[0])  
         } else {
             setError('upload', { message: 'Format d\'image non valide' });
             setFileUpload();
             document.getElementById('fileUploaded').value = "";
         }
     }
     const handleRemoveFile = () => {
        setFileUpload()
        document.getElementById('fileUploaded').value = ""
     }
     
    let [paramsSendUpload, setParamsSendUpload] = useState({});
    const [responseSendUpload, isLoadedSendUpload] = useApi(paramsSendUpload);

    const sendUpload = (token, data) => {
        const formData = new FormData();
        formData.append("file", data.upload[0]);
        const headers = {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": token
        };

        setParamsSendUpload({
            name: `api/lba-operations/upload?_format=json&operation=${dataDecoupled.data.lba_operations.operation_id}`,
            method: 'post',
            data: formData,
            config: headers
        });
         
    }

    useEffect(() => {
        if (responseSendUpload && isLoadedSendUpload) {
            let {status, data} = responseSendUpload;
            if (status === 201) {
                // Soumission des uploads OK

                if(dataInfos.data && dataInfos.dataSend){
                    let temp = dataInfos.dataSend;
                    temp.filename = data;
                    SendData(data, temp);
                    setDataInfos({});
                }
                
            } else {
                console.log('error');
            }
        }
    }, [responseSendUpload, isLoadedSendUpload]); // eslint-disable-line


    const renderPage = () => {
        const {metatag, fieldCommonPgphBan, fieldOperationsListPagetype, fieldCommonPgphContents: flexibles, fieldCommonTrUniverse, fieldCommonTrBrands} = dataRedux;

        let brandIDs;
        let brandsList;
        if (fieldCommonTrBrands && fieldCommonTrBrands.length > 0) {
            let brandsArray = [];
            fieldCommonTrBrands.map(universe => {
                brandsArray.push(universe.fieldApiRemoteidBrandid);
                return null;
            });
            brandIDs = brandsArray;

            // Get brands name
            let brandsListArray = [];
            fieldCommonTrBrands.map(item => {
                brandsListArray.push(item.name);
                return null;
            });
            brandsList = brandsListArray;
        }
        let universeIDs;
        if (fieldCommonTrUniverse && fieldCommonTrUniverse.length > 0) {
            let universesArray = [];
            fieldCommonTrUniverse.map(universe => {
                universesArray.push(universe.fieldApiRemoteidUniverseid);
                return null;
            });
            universeIDs = universesArray;
        }

        //-- Tracking page_view
        let pageviewEvent = {
            category: dataDecoupled && dataDecoupled.data.label,
            category2: 'Jeu',
            category3: brandsList && brandsList.join(', ')
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}

                {fieldCommonPgphBan &&
                <VisuelLarge urlVisuel={fieldCommonPgphBan} desktop={desktop} isTrueSize="true"/>
                }

                <div className="container" id="printContainer">

                    {
                        flexibles[0]?.paragraphType?.label !== "Image responsive avec légende" && <div className="t-landingPage_container rounded--">
                            &nbsp;
                        </div>
                    }

                    {(flexibles && flexibles.length > 0) &&
                    flexibles.map(({paragraphType: {label}, fieldCommonTitle, fieldCommonTxtTextbody, fieldEconomyTxtEanCodes, fieldEconomyTxtOfferrefs, fieldOperationsTxtCtalabel, fieldOperationsTxtUploadBt, fieldOperationsTxtQuote, fieldOperationsTxtQuestion, fieldOperationsTxtOptin, fieldCtaLanding: cta, fieldLandingVideo: video, fieldCommonPgphBan: img, fieldCommonTxtTexttitle: legend, fieldLandingPgphSteps: steps}, index) => {

                        return (
                            <Fragment key={index}>
                                {
                                    {
                                        'Operation': (fieldCommonTitle || fieldCommonTxtTextbody) &&
                                            <div className="t-landingPage_container">
                                
                                                {fieldOperationsListPagetype == 'event' && fieldOperationsTxtOptin ?
                                                    <div className="c-wysiwygSimple">
                                                        <div className="c-wysiwygSimple_content c-wysiwyg">
                                                            {fieldCommonTitle && <h1>{fieldCommonTitle.processed}</h1>}
                                                            {isLoadedDecoupled && dataDecoupled.data.lba_operations.operation_type != "1" &&
                                                                <form className="c-form is-grey--"
                                                                    onSubmit={handleSubmit(onSubmit)}>
                                                                    
                                                                    {fieldOperationsTxtQuestion &&
                                                                    <div className="c-form_line">
                                                                        <div className="c-form_lineEltLarge">
                                                                            <label>{fieldOperationsTxtQuestion}</label>
                                                                            <input type="text" placeholder={withCode ? "Votre code avantage" : "Votre réponse"}
                                                                                name="comment"
                                                                                className={withCode ? "withUppercase" : ""}
                                                                                id="comment"
                                                                                ref={register({
                                                                                    required: true
                                                                                })}
                                                                                maxLength="500"
                                                                                data-error={errors.comment}
                                                                            />
                                                                            {(errors.comment && errors.comment.type === 'required') &&
                                                                            <div
                                                                                className="error--">Le champs doit être renseigné.</div>}
                                                                            {
                                                                                errorAvantage != "" && <div className="error--">{errorAvantage}</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {fieldOperationsTxtOptin &&
                                                                    <div className="c-form_line">
                                                                        <div className="c-form_lineEltLarge">
                                                                            <div className="c-form_checkbox">
                                                                                <input type="checkbox" name="optin"
                                                                                    id="optin"
                                                                                    ref={register({
                                                                                        required: true
                                                                                    })}
                                                                                    value="1"
                                                                                />
                                                                                <label
                                                                                    htmlFor="optin"
                                                                                    dangerouslySetInnerHTML={{__html: fieldOperationsTxtOptin}}/>
                                                                            </div>
                                                                            {(errors.optin && errors.optin.type === 'required') &&
                                                                            <div
                                                                                className="error--">Veuillez cocher cette case.</div>}
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {(dataDecoupled.data.lba_operations.upload.type === "receipt" || dataDecoupled.data.lba_operations.upload.type === "image") &&
                                                                        <div className={`t-operationsPage__upload ${fileUpload ? "selected" : ""}`}>
                                                                            {
                                                                                fileUpload &&
                                                                                <div className="t-operationsPage__upload-file-name">
                                                                                    <p >{fileUpload.name}</p>
                                                                                    <i onClick={() => handleRemoveFile() } className="icon-delete" />
                                                                                </div>
                                                                            }
                                                                            <div className="t-operationsPage__upload-file">
                                                                                <button type="button" className="t-operationsPage__upload-file-btn">
                                                                                    <i className="icon-photo" />
                                                                                    <strong>{fileUpload ? "Importer de nouveau" : fieldOperationsTxtUploadBt}</strong>
                                                                                </button>
                                                                                <input
                                                                                    ref={register({
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: 'Veuillez importer un fichier'
                                                                                        }
                                                                                    })}
                                                                                    id="fileUploaded"
                                                                                    onChange={(e) => handleInputUploadsChange(e)}
                                                                                    name="upload"
                                                                                    type="file"
                                                                                    accept={`image/png, image/jpeg, image/jpg, ${dataDecoupled.data.lba_operations.upload.type === "receipt" ? "application/pdf" : ""}`}
                                                                                />
                                                                            </div>
                                                                            {errors.upload && (<p className="error">{errors.upload.message}</p>)}
                                                                            <p className="t-operationsPage__upload-format">{`Au format png, jpg, jpeg${dataDecoupled.data.lba_operations.upload.type === "receipt" ? ", pdf" : ""}. Taille maximale 10Mo`}</p>
                                                                            
                                                                        </div>
                                                                    }
                                                                    {loading ?
                                                                        <Loader/>
                                                                        :
                                                                            <div className="c-form_submit">
                                                                                <button type="submit" className="btn-3">{fieldOperationsTxtCtalabel ? fieldOperationsTxtCtalabel : "Je confirme ma participation"}</button>
                                                                            </div>

                                                                    }
                                                                </form>
                                                            }
                                                            {fieldCommonTxtTextbody &&
                                                                <WysiwygSimple
                                                                title=""
                                                                content={fieldCommonTxtTextbody && fieldCommonTxtTextbody.processed}/>
                                                            }
                                                        </div>
                                                    </div>

                                                    :
                                                        
                                                    <WysiwygSimple
                                                        title={fieldCommonTitle && fieldCommonTitle.processed}
                                                        content={fieldCommonTxtTextbody && fieldCommonTxtTextbody.processed}/>
                                                }
                                                {fieldOperationsTxtQuote &&
                                                <div className="t-operationsPage_blockquote"
                                                     dangerouslySetInnerHTML={{__html: fieldOperationsTxtQuote.processed}}/>
                                                }
                                                {
                                                    fieldOperationsTxtCtalabel && fieldOperationsListPagetype !== 'online' && (isLoadedDecoupled && dataDecoupled.data.lba_operations.operation_type === "1") && fieldOperationsListPagetype !== "already_participated" &&
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        {(dataDecoupled.data.lba_operations.upload.type === "receipt" || dataDecoupled.data.lba_operations.upload.type === "image") &&
                                                            <div className={`t-operationsPage__upload ${fileUpload ? "selected" : ""}`}>
                                                                {
                                                                    fileUpload &&
                                                                    <div className="t-operationsPage__upload-file-name">
                                                                        <p >{fileUpload.name}</p>
                                                                        <i onClick={() => handleRemoveFile() } className="icon-delete" />
                                                                    </div>
                                                                }
                                                                <div className="t-operationsPage__upload-file">
                                                                    <button type="button" className="t-operationsPage__upload-file-btn">
                                                                        <i className="icon-photo" />
                                                                        <strong>{fileUpload ? "Importer de nouveau" : fieldOperationsTxtUploadBt}</strong>
                                                                    </button>
                                                                    <input
                                                                        ref={register({
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Veuillez importer un fichier'
                                                                            }
                                                                        })}
                                                                        id="fileUploaded"
                                                                        onChange={(e) => handleInputUploadsChange(e)}
                                                                        name="upload"
                                                                        type="file"
                                                                        accept={`image/png, image/jpeg, image/jpg, ${dataDecoupled.data.lba_operations.upload.type === "receipt" ? "application/pdf" : ""}`}
                                                                    />
                                                                </div>
                                                                {errors.upload && (<p className="error">{errors.upload.message}</p>)}
                                                                <p className="t-operationsPage__upload-format">{`Au format png, jpg, jpeg${dataDecoupled.data.lba_operations.upload.type === "receipt" ? ", pdf" : ""}. Taille maximale 10Mo`}</p>
                                                                
                                                            </div>
                                                        }
                                                        <div className="t-operationsPage_cta">
                                                            <button type="submit"
                                                                    className="btn-3">{fieldOperationsTxtCtalabel}</button>
                                                        </div>
                                                    </form>
                                                }
                                                {fieldOperationsListPagetype === 'online' && lbaOperations && lbaOperations.operation_link_url &&
                                                <>
                                                    <div className="t-operationsPage_cta">
                                                        <button type="button" className="btn-3"
                                                                onClick={(event) => participate(event)}>{fieldOperationsTxtCtalabel ? fieldOperationsTxtCtalabel : "Je participe au jeu concours"}</button>
                                                    </div>
                                                </>
                                                }

                                            </div>,
                                        'Texte': (fieldCommonTitle || fieldCommonTxtTextbody) &&
                                            <div className="t-landingPage_container">
                                                <WysiwygSimple title={fieldCommonTitle && fieldCommonTitle.processed}
                                                               content={fieldCommonTxtTextbody && fieldCommonTxtTextbody.processed}/>
                                            </div>,
                                        'Remontée des informations': (userInfo && fieldOperationsListPagetype === 'thanks') &&
                                            <div className="t-landingPage_container">
                                                <div className="c-wysiwygSimple">
                                                    <div className="c-wysiwygSimple_content c-wysiwyg">
                                                        <div className="t-operationsPage_form-box">
                                                            <div className="c-form">
                                                                <div className="c-form_line">
                                                                    <div className="c-form_lineElt">
                                                                        {/* {fname && <label>{fname} <sup>*</sup></label>} */}
                                                                        <label>Nom*</label>
                                                                        <input type="text" placeholder={userInfo.lba_lname} name="userLastname"
                                                                            id="userLastname"
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className="c-form_lineElt">
                                                                        {/* {fname && <label>{fname} <sup>*</sup></label>} */}
                                                                        <label>Prénom*</label>
                                                                        <input type="text" placeholder={userInfo.lba_fname} name="userFirstname"
                                                                            id="userFirstname"
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="c-form_line">
                                                                    <div className="c-form_lineEltLarge">
                                                                        <label>Email*</label>
                                                                        <input type="text" placeholder={userInfo.lba_email} name="userEmail"
                                                                            id="userEmail"
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="c-form_line">
                                                                    <div className="c-form_lineEltLarge">
                                                                        <label>Adresse Postale*</label>
                                                                        <div className="address">
                                                                            <div className="address_line">
                                                                                <p>{userInfo.lba_address}</p>
                                                                            </div>
                                                                            {userInfo.lba_address2 &&
                                                                            <div className="address_line">
                                                                                <p>{userInfo.lba_address2}</p>
                                                                            </div>
                                                                            }
                                                                            {(userInfo.lba_zip || userInfo.lba_city) &&
                                                                            <div className="address_line">
                                                                                <p>{userInfo.lba_zip && userInfo.lba_zip} {userInfo.lba_city && userInfo.lba_city}</p>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {userInfo.lba_mobilephone &&
                                                                <div className="c-form_line">
                                                                    <div className="c-form_lineEltLarge">
                                                                        <label>Téléphone</label>
                                                                        <input type="text" placeholder={userInfo.lba_mobilephone}
                                                                            name="userPhone"
                                                                            id="userPhone"
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                }

                                                                <div className="c-form_submit">
                                                                    <CLink url={accountEditPath} className="btn-3"
                                                                        target="_self">Modifier mes informations</CLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>,
                                        'Tableau': fieldCommonTxtTextbody &&
                                            <div className="t-landingPage_container">
                                                {legend && <h3 className="c-table_title">{legend}</h3>}
                                                <Tableau {...fieldCommonTxtTextbody}/>
                                            </div>,
                                        'CTA ': (cta && cta.url && cta.uri && cta.title) &&
                                            <div className="t-landingPage_container">
                                                <Cta url={cta.url}
                                                     target={cta.uri.includes("entity:node/") ? "_self" : "_blank"}
                                                     label={cta.title}/>
                                            </div>,
                                        'Bloc Cashback': <CarouselCashback desktop={desktop}
                                                                         universeID={universeIDs && universeIDs}
                                                                         brandID={brandIDs && brandIDs}
                                                                         offerRefs={fieldEconomyTxtOfferrefs && fieldEconomyTxtOfferrefs}
                                                                         showNoResults={true}/>,
                                        'Bloc Webcoupons': <CarouselWebcoupon desktop={desktop}
                                                                         universeID={universeIDs && universeIDs}
                                                                         brandID={brandIDs && brandIDs}
                                                                         eanCodes={fieldEconomyTxtEanCodes && fieldEconomyTxtEanCodes}
                                                                         showNoResults={true}/>,
                                        'Vidéo': (video && video.filename) &&
                                            <div className="t-landingPage_container">
                                                <Video infoVideo={video}
                                                       infoImage={img} desktop={desktop}/>
                                            </div>,
                                        'Image responsive avec légende': img &&
                                            <div className="t-landingPage_container">
                                                <VisuelLegend visuel={img}
                                                              legend={legend && legend} desktop={desktop}/>
                                            </div>,
                                        'Etapes': (steps && steps.length > 0) &&
                                            <div className="t-landingPage_container">
                                                <Steps listStep={steps} desktop={desktop}/>
                                            </div>
                                    }[label]

                                }
                            </Fragment>
                        )
                    })
                    }

                </div>
            </>
        )
    };
    return (isLoaded && dataRedux && readyToPlay && pageDataIsLoaded) && isLoadedDecoupled ? renderPage() : <Loader/>
};

export default TemplateOperationsPage;