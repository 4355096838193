import React, {useEffect, useRef, useCallback} from "react";
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage} from '../Store/action';

// Libraries
import Axios from "axios";
// import Oauth from axios-oauth-client;
import {useForm, Controller} from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import AppleLogin from 'react-apple-login'
import Select from "react-select";
import {renderCountries} from '../Utils/renderCountry';
import {getCookie} from '../Utils/cookie';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import Tooltip from "../Components/tooltip"
import {Loader} from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import Wysiwyg from "../Components/wysiwygSimple";
import {strengthIndicator, strengthValueIndicator} from "../Components/strength-password";
import { useSafeState } from "../Hooks/useSafeState";
import { useHistory } from "react-router-dom";
//import CLink from "../Components/cLinks";
// import ComponentTest from '../Components/flexible/component-test';


const TemplateRegister = ({_uid}) => {
    const baseURL = process.env.REACT_APP_APP;
    const {desktop} = useSelector(state => state.breakpoint);
    const { uuid } = useSelector(state => state.user);
    const routes = useSelector(state => state.global).routes;
    const global = useSelector(state => state.global).global;
    const history = useHistory()

    let [loading, setLoading] = useSafeState(false);

    // Get Log from url
    const logFromPath = useSelector(state => state.page).logFrom;
    // Get domain url
    const domain = 'https://' + window.location.host;

    // Info générales de la page
    const [dataRedux, setDataRedux] = useSafeState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        // uuid != null then redirect to account page
        if (uuid && (uuid != null || uuid !== "")) {
            let accountRoute = routes.filter(route => route.component === "entity.canonical.node.special.account_home")[0]
            if (accountRoute) {
                history.push(accountRoute.path)
            }
        }
    }, [uuid])

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); //eslint-disable-line

    // TRACKING
    // const formTracking = ({ uuid }) => {
    //     // register
    //     window.customDataLayer.push({
    //         event: 'sign_up',
    //        method: 'email',
            //         user_id: uuid,
    //     });
    //     // console.log('customDataLayer [register] ', window.customDataLayer);
    // };

    // Tooltip Info
    const tooltipInfoRef = useRef();
    const tooltipHelpRef = useRef();
    const tooltipHelp2Ref = useRef();
    const [showTooltipInfo, setShowTooltipInfo] = useSafeState({
        show: false,
        content: '',
        ref: '',
        type: 'info'
    });
    const handleMouseTooltip = (e) => {
        if (showTooltipInfo.show) {

            setShowTooltipInfo({
                show: false,
                content: '',
                ref: '',
                type: 'info'
            });
        }
        else {
            let refTooltip = "";
            switch (e.target.dataset.typeref) {
                case 'tooltipInfoRef' :
                    refTooltip = tooltipInfoRef.current;
                    break;

                case 'tooltipHelpRef' :
                    refTooltip = tooltipHelpRef.current;
                    break;

                case 'tooltipHelp2Ref' :
                    refTooltip = tooltipHelp2Ref.current;
                    break;

                default:
                    break;
            }
            setShowTooltipInfo({
                show: true,
                content: e.target.dataset.content,
                ref: refTooltip,
                type: e.target.dataset.type
            });
        }

    };
    // END Tooltip Info

    // Hide / Show password
    const [revealPassword, setrevealPassword] = useSafeState(false);
    const [revealPasswordConfirm, setrevealPasswordConfirm] = useSafeState(false);
    const handleClickPassword = (e) => {
        //  console.log(e.target.dataset.type);

        switch (e.target.dataset.type) {
            case 'lba_lbapassword':
                setrevealPassword(!revealPassword);
                break;

            case 'lba_lbapassword2':
                setrevealPasswordConfirm(!revealPasswordConfirm);
                break;
            default:
                break;
        }

    };
    // END Hide / Show password

    //-- BIRTH
    const [birthError, setBirthError] = useSafeState({message: ''});

    // Get age
    const getAge = (dateString) => {
        let dateArray = dateString.split('/');
        dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    // On key up on birth field
    const handleBirth = (e) => {
        //-- Add slashes
        if (e.target.value && e.target.value.length > 0) {
            if ((e.target.value.length == 2 || e.target.value.length == 5) && e.keyCode != 8) { //eslint-disable-line
                e.target.value = e.target.value + '/';
            }
        }

        //-- Check age
        if (e.target.value.length == 10) { //eslint-disable-line
            if (getAge(e.target.value) < 18) {
                setBirthError({message: "Vous devez être majeur pour vous inscrire."})
            } else {
                setBirthError({message: ''})
            }
        }
    }

    // Errors - existing fields
    const [existingEmail, setExistingEmail] = useSafeState(false);
    const [existingName, setExistingName] = useSafeState(false);
    const [existingAccount, setExistingAccount] = useSafeState(false);

    // Scroll to errors section
    const scrollToError = (id) => {
        const yOffset = -document.querySelector('.c-header').offsetHeight;
        const element = document.getElementById(id);
        if (element && yOffset) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }

    // Remove error onChange
    const handleRemoveErrors = (e) => {
        // Reset error field
        if (existingEmail) setExistingEmail(false);
        if (existingName) setExistingName(false);
        if (existingAccount) setExistingAccount(false);
    }


    // Tooltip Adresse Autocomplete
    const tooltipContainer = useRef();
    const [showTooltip, setShowTooltip] = useSafeState({
        show: false,
        value: ''
    });
    let [adresseAutocomplete, setAdresseAutocomplete] = useSafeState({
        name: '',
        postcode: '',
        city: ''
    });
    const handleChangeAdresse = (e) => {
        setAdresseAutocomplete({...adresseAutocomplete, name: e.target.value});
        setValue("lba_address", e.target.value)
        let postcode = document.querySelector('#lba_zip').value;
        if (e.target.value.length > 3) {
            //appel de l'api
            let fetch = Axios.get(`https://api-adresse.data.gouv.fr/search/?type=&autocomplete=1&q=${e.target.value}&postcode=${postcode}&limit=5`);
            fetch.then(function (response) {
                // handle success
                const result = handleSuccessAutoCompletion(response.data.features);
                setShowTooltip({show: true, value: result});
            });
        }
        else {
            setShowTooltip({show: false, value: ''})
        }

        if (existingAccount) setExistingAccount(false);
    }
    const handleSuccessAutoCompletion = (data) => {
        return (
            <ul>
                {Object.keys(data).map((keyName, i) => (
                    <li key={keyName}
                        data-name={data[i].properties.name}
                        data-postcode={data[i].properties.postcode}
                        data-city={data[i].properties.city}
                        onClick={autocompleAdress}>
                        {data[i].properties.label}
                    </li>
                ))}
            </ul>
        )
    };
    const autocompleAdress = useCallback(
        (e) => {
            setAdresseAutocomplete({
                name: e.target.dataset.name,
                postcode: e.target.dataset.postcode,
                city: e.target.dataset.city
            });
            setShowTooltip({show: false});
            setValue("lba_address", e.target.dataset.name)
            setValue("lba_zip", e.target.dataset.postcode)
            setValue("lba_city", e.target.dataset.city)
        },
        [adresseAutocomplete] // eslint-disable-line
    );
    const handleLeaveAddressInput = (e) => {
        setAdresseAutocomplete({name: e.target.value});
        //setValue("lba_address", e.target.dataset.name)
        setTimeout(() => {
            setShowTooltip({show: false});
        }, 300);
    };
    // END Tooltip Adresse Autocomplete


    // ID Login with social network
    // const googleClientID = '1074795854321-b739mcc9hi8mjnn6qs475iok43b1krtp.apps.googleusercontent.com';
    // const facebookClientID = '1245320885850630';
    // const appleClientID = 'com.labelleadresse.sso';

    // const host = window.location.origin

    // const socialResponseFacebook = (response) => {
    //     console.log(response)
    //     if (response) {

    //     }
    // };

    // const socialResponseGoogle = (response) => {
    //     console.log(response)
    //     //     if (response) {
    //     //         if (response.error) {
    //     //             handleFailure('Google : ' + response.details);
    //     //         } else {
    //     //             let {profileObj: {googleId, email, givenName, familyName}, tokenId} = response;
    //     //             let formData = new FormData();
    //     //             googleId && formData.append('id', googleId);
    //     //             email && formData.append('mail', email);
    //     //             givenName && formData.append('firstname', givenName);
    //     //             familyName && formData.append('lastname', familyName);
    //     //             tokenId && formData.append('token', tokenId);
    //     //             formData.append('type', 'google');
    //     //             socialConvert(formData);
    //     //         }
    //     //     }
    // };

    // const socialResponseApple = (response) => {
    // };


    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-register'));
    }, [dispatch]); // isLoaded


    // Recaptcha
    //let lang = "fr";

    const [recaptchaValue, setRecaptchaVal] = useSafeState(null);
    const [emptyRecaptcha, setEmptyRecaptcha] = useSafeState(false);
    let captchaRef = useRef(null);
    // const recaptchaKey = '6Lckv_QZAAAAANfqd0W6RUfOVvnGI-5ez67UyFHx';
    const recaptchaKey = useSelector(state => state.global).global.recaptcha.public_key;

    // Register form
    const {register, handleSubmit, setValue, errors, control, watch} = useForm({
        defaultValues: {
            'g_recaptcha_response': recaptchaValue
        }
    });
    const formCode = useForm();

    // Countries list
    const options = renderCountries();

    // Check confirm password
    const newPassword = useRef({});
    newPassword.current = watch("lba_lbapassword", "");

    // Check password format
    const [invalidPwd, setInvalidPwd] = useSafeState(false);
    //Strength password
    let [password, setPassword] = useSafeState({
        indicator: strengthIndicator(''),
        value: strengthValueIndicator('')
    });
    const handlePwdChange = (e) => {
        setPassword({
            indicator: strengthIndicator(e.target.value),
            value: strengthValueIndicator(strengthIndicator(e.target.value))
        });

        // Check password format
        if (!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
            // Doit contenir 1 majuscule, 1 minicule, 1 chiffre
            setInvalidPwd(true)

            // Peut contenir caractère special
            if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)) {    // eslint-disable-line
                setInvalidPwd(false)
            }
        } else {
            setInvalidPwd(false)
        }
    };


    // Confirmation screen
    let [registerSuccess, setRegisterSuccess] = useSafeState(false);
    let pageUrl;

    const onSubmit = (dataForm) => {
        // console.log('dataForm', dataForm);
        // console.log('logFromPath', logFromPath);

        if(logFromPath.includes("/bienvenue")) {
            pageUrl = logFromPath.replace('/bienvenue','')
        } else if(logFromPath.includes("/remerciement")) {
            pageUrl = logFromPath.replace('/remerciement','')
        }
        else {
            pageUrl = logFromPath;
        }

        if ('g_recaptcha_response' in dataForm) {
            delete dataForm.g_recaptcha_response;
        }

        if ('lba_civilite' in dataForm) {
            dataForm.lba_civilite = parseInt(dataForm.lba_civilite);
        }

        if ('lba_birth' in dataForm) {
            //On scinde les jours mois année
            const dateBirth = dataForm.lba_birth.split('/');
            dataForm.lba_birthday = parseInt(dateBirth[0]);
            dataForm.lba_birthmonth = parseInt(dateBirth[1]);
            dataForm.lba_birthyear = parseInt(dateBirth[2]);
            delete dataForm.lba_birth;
        }
        if ('lba_countrycode' in dataForm) {
            // console.log('lba_countrycode', dataForm.lba_countrycode);
            dataForm.lba_countrycode = dataForm.lba_countrycode.value;
        }

        if ('lba_lbapassword' in dataForm) {
            // Hide password
            setrevealPassword(false);
            setrevealPasswordConfirm(false);
            delete dataForm.lba_lbapassword2;
        }

        const dataSend = {
            data: {
                type: "users",
                attributes: {
                    'lba_thengo': domain + pageUrl + '?show_login=confirmation',
                    'lba_idCampagne': getCookie('srcid') ? parseInt(getCookie('srcid'), 10) : ''
                },
            }
        };
        // console.log('dataSend ', dataSend);
        Object.assign(dataSend.data.attributes, dataForm);

        /* setTimeout(function(){
             console.log('THEN GO', dataSend);
         },0);
 */
        // Recaptcha value REQUIRED
        if (captchaRef.current.getValue() == '') {    // eslint-disable-line
            // if no recaptcha value, show error message
            setEmptyRecaptcha(true);
            return false;
        } else {

            // Show loader
            setLoading(true);

            // Récupération du token à passer ds le header
            let fetchJeton = Axios.post(`${baseURL}/session/token/`);

            fetchJeton.then(res => {
                let {status, data} = res;

                if (status === 200) {
                    SendData(data, dataSend);

                    // console.log('dataform ', data);

                } else {
                    //  console.log('erreur', data);

                    // Hide loader
                    setLoading(false);
                }
            })
        }
    };

    let [paramsSend, setParamsSend] = useSafeState({})
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        const headers = {
            "Content-Type": "application/vnd.api+json",
            "X-CSRF-Token": jeton
        };
        setParamsSend({
            name: 'api/users',
            recaptcha_token: recaptchaValue,
            method: 'post',
            data: dataSend,
            config: headers,
        });
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let { status, data } = responseSend;
            // console.log(data);
            setErrorGlobal(false);
            setErrorDupli(false);
            setErrorCode(false);
            setErrorCodeAlreadyUse(false);
    
            if (status === 201) {
                //tout est ok
                //console.log('success / data : ', data);
                // Show success screen
                setRegisterSuccess(true);
                window.scrollTo({ top: 0, behavior: 'smooth' });
    
                // TRACKING
                // formTracking({ uuid: data.data.id });
                window.customDataLayer.push({
                    event: 'generate_lead',
                    user_id: data.data.attributes.lba_userid,
                    lead_type: 'inscription_formulaire'
                });
            }
            else {
                // Reset ReCaptcha
                captchaRef.current.reset();
                setRecaptchaVal(null);
    
                // Existing fname + lname + address
                if (data.errors[0].detail.indexOf('Based on first name') > -1 || data.errors[0].detail.indexOf('address_ref: Based on first name, last name and address, a web account already exists for this person.') > -1 || data.errors[0].detail.indexOf("CONTACT_ADDRESS_UNICITY") > -1) { // eslint-disable-line
                    setErrorDupli(true);
                    setExistingAccount(true);
                    //scrollToError('form_civility');
                }
                else if (data.errors[0].detail == "Entity is not valid: REGISTRATION_CODE_NOT_FOUND].") {
                    scrollToError('form_avantage');
                    setErrorCode(true);
                }
                else if (data.errors[0].detail.indexOf("REGISTRATION_CODE_ALREADY_USED") > -1) {
                    scrollToError('form_avantage');
                    setErrorCodeAlreadyUse(true);
                }
                else if (data.errors[0].source) {
                    // Existing email
                    if (data.errors[0].source.pointer == '/data/attributes/mail' || data.errors[0].source.pointer == '/data/attributes/lba_email') setExistingEmail(true);    // eslint-disable-line
    
                    // Existing name
                    if (data.errors[0].source.pointer == '/data/attributes/name' || data.errors[0].source.pointer == '/data/attributes/lba_lbalogin') setExistingName(true);    // eslint-disable-line
    
                    scrollToError('form_username');
                } else {
                    setErrorGlobal(true);
                }
            }
    
            // Hide loader
            setLoading(false);
        }
    }, [responseSend, isLoadedSend]);


    /******************* PARTIE CODE AVANTAGE ***************/
    let [showCta, setShowCta] = useSafeState(false)
    let [loadingAvantage, setLoadingAvantage] = useSafeState(false);
    let [paramsCode, setParamsCode] = useSafeState({})
    let [errorCode, setErrorCode] = useSafeState(false)
    let [errorCodeAlreadyUsed, setErrorCodeAlreadyUse] = useSafeState(false)
    let [errorGlobal, setErrorGlobal] = useSafeState(false)
    let [errorDupli, setErrorDupli] = useSafeState(false)
    let [successCode, setSuccessCode] = useSafeState(false)
    let [formDataCode, setFormDataCode] = useSafeState({})

    // lba_address: "",
    // lba_address2: "",
    // lba_city:"",
    // lba_civilite:"1",
    // lba_countrycode: "",
    // lba_email: "",
    // lba_fname: "",
    // lba_id: 0,
    // lba_lbalogin: "",
    // lba_lname: "",
    // lba_mobilephone: "",
    // lba_zip: ""

    let [formNumAdherent, setFormNumAdherent] = useSafeState('')
    const [responseCode, isLoadedCode] = useApi(paramsCode);
    const codeRef = useRef();
    const civilite = watch('civilite');
    //Affichage du CTA si 1 caractere de saisi
    const handleChangeCode = () => {
        if(codeRef.current?.value.length >= 1) {
            setShowCta(true);
        }
        else {
            setShowCta(false);
        }
    }
    const handleBlurCode = () => {
        const inputValue = codeRef.current?.value;
        if(typeof(inputValue) === "string") {
            setFormNumAdherent(inputValue.toUpperCase());
        }
    }

    //Au submit du formulaire
    const onSubmitCode = () => {
        const inputValue = codeRef.current?.value;
        if(inputValue && inputValue.length >= 1 ) {
            setLoadingAvantage(true);
            const inputValueInUpperCase = inputValue ? inputValue.toUpperCase() : '';
            setParamsCode({
                name: 'api/user/from-registration-code?code='+inputValueInUpperCase,
                method: 'get'
            });
        }
        else {
            setShowCta(false);
        }
    }

    //A la reponse de l'api ==> preremplissage
    useEffect(() => {
        if(isLoadedCode) {
            setParamsCode({});
            setErrorCode(false);
            setErrorCodeAlreadyUse(false);
            if(responseCode.status === 200) {

                setSuccessCode(true);
                
                if(responseCode.data.lba_civilite && responseCode.data.lba_civilite !== "")
                {
                    setValue('lba_civilite', responseCode.data.lba_civilite);
                }

                if(responseCode.data.lba_fname && responseCode.data.lba_fname !== "")
                {
                    setValue("lba_fname", responseCode.data.lba_fname)
                }

                if(responseCode.data.lba_lname && responseCode.data.lba_lname !== "")
                {
                    setValue("lba_lname", responseCode.data.lba_lname)
                }

                if(responseCode.data.lba_address && responseCode.data.lba_address !== "")
                {
                    setValue("lba_address", responseCode.data.lba_address)
                }

                if(responseCode.data.lba_address2 && responseCode.data.lba_address2 !== "")
                {
                    setValue("lba_address2", responseCode.data.lba_address2)
                }

                if(responseCode.data.lba_zip && responseCode.data.lba_zip !== "")
                {
                    setValue("lba_zip", responseCode.data.lba_zip)
                }

                if(responseCode.data.lba_city && responseCode.data.lba_city !== "")
                {
                    setValue("lba_city", responseCode.data.lba_city)
                }

                if(responseCode.data.lba_mobilephone && responseCode.data.lba_mobilephone !== "")
                {
                    setValue("lba_mobilephone", responseCode.data.lba_mobilephone)
                }

                if(responseCode.data.lba_email && responseCode.data.lba_email !== "")
                {
                    setValue("lba_email", responseCode.data.lba_email)
                }

                if(responseCode.data.lba_lbalogin && responseCode.data.lba_lbalogin !== "")
                {
                    setValue("lba_lbalogin", responseCode.data.lba_lbalogin)
                }
                    
                let country = options.find(o=> o.value === responseCode.data.lba_countrycode);
                if(country) setValue("lba_countrycode", country)

                setFormNumAdherent(codeRef.current.value);
            }
            else {
                setFormDataCode("");
                //erreur
                if(responseCode.data.error_code || responseCode.status === 204) {
                    setErrorCode(true);
                    setSuccessCode(false);
                }
            }
            setLoadingAvantage(false);
        }
    }, [responseCode, isLoadedCode]);

    //Check si on affiche ou non le formulaire de 
    const shouldShowForm = () => {
        if(global.registration_code && global.registration_code.open_datetime && global.registration_code.close_datetime) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate());
            const currentTimestamp = currentDate.getTime();
            const openTimestamp = new Date(global.registration_code.open_datetime).getTime();
            const closeTimestamp = new Date(global.registration_code.close_datetime).getTime();
            if(currentTimestamp >= closeTimestamp && currentTimestamp <= openTimestamp) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return true;
        }
    }
    let [showForm, setshowForm] = useSafeState(shouldShowForm());

    const renderPage = () => {

        const {metatag} = dataRedux;

        //-- Tracking
        let pageviewEvent = {
            category: 'Inscription',
            category2: '',
            category3: ''
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}

                <div className="container">
                    {registerSuccess ?
                        <>
                            <VisuelLarge urlVisuel="" urlVisuelStatic={require('../assets/img/register.jpg')}
                                         desktop={desktop}/>
                            <div className="container">
                                <div className="t-contentPage_container text-center">
                                    <Wysiwyg title="Vous avez presque terminé"
                                             content="<strong>Pour valider votre inscription</strong> rendez-vous dans votre boîte mail et cliquez sur le lien de confirmation.<br/><br/>Vous n’avez pas reçu d’email de notre part ?<br/><br/>Pensez à vérifier dans vos courriers indésirables et sur Gmail dans l’onglet Promotions ou le dossier Forums sous Catégories."/>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="t-register_intro">
                                <h1 className="title-2">Rejoignez la belle adresse</h1>
                                <p>En vous inscrivant à La Belle Adresse, vous pourrez profiter pleinement de tous les avantages liés au site : imprimer vos réductions ou vous faire rembourser vos achats, découvrir et commenter nos articles, partager avec la communauté et plein d’autres choses encore… </p>
                            </div>


                            {/*<ul className="t-register_rs c-button_rs">
                        <li>
                            <FacebookLogin
                                appId={facebookClientID}
                                fields="email, first_name, last_name"
                                autoLoad={false}
                                callback={socialResponseFacebook}
                                authType="oAuth2"
                                render={renderProps => (
                                    <button type="button"
                                            className="c-button_rsItem c-button_facebook"
                                            onClick={renderProps.onClick}>Je me connecte avec Facebook</button>
                                )}
                            />
                        </li>
                        <li>
                            <GoogleLogin
                                clientId={googleClientID}
                                autoLoad={false}
                                render={({onClick}) => (
                                    <button type="button"
                                            className="c-button_rsItem c-button_google"
                                            onClick={onClick}>Je me connecte avec Google</button>
                                )}
                                onSuccess={socialResponseGoogle}
                                onFailure={socialResponseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </li>
                        <li>
                            <AppleLogin
                                clientId={appleClientID}
                                autoLoad={false}
                                redirectURI={host}
                                callback={socialResponseApple}
                                usePopup={true}
                                render={({onClick}) => (
                                    <button type="button"
                                            className="c-button_rsItem c-button_apple"
                                            onClick={onClick}>Je me connecte avec Apple</button>
                                )}
                            />
                        </li>
                    </ul> */}
                        {showForm && (
                            <form className="t-register_formAvantage c-form" onSubmit={formCode.handleSubmit(onSubmitCode)}
                                  autoComplete="off">
                                <h2 id="form_avantage" className="title-1 lowercase--">Je possède un code avantage</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineEltLarge">
                                        <label htmlFor="lba_codeAvantage">Si vous possédez un code avantage, merci de l'indiquer ici</label>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <input type="text" placeholder="Votre code à 10 caractères" name="lba_codeAvantage"
                                            id="lba_codeAvantage"
                                            maxLength="10"
                                            ref={codeRef}
                                            onChange={handleChangeCode}
                                            onBlur={handleBlurCode}
                                        />
                                        {(errorCode) &&
                                        <div className="error--">Ce code n'est pas valide.</div>}
                                        {(errorCodeAlreadyUsed) &&
                                        <div className="error--">Ce code a déjà été utilisé.</div>}
                                        {(successCode) &&
                                        <div className="succes--">Le code a bien été identifié.</div>}
                                    </div>
                                    <div className="c-form_lineElt">
                                        {!loadingAvantage ?
                                            showCta &&
                                                <button type="submit" className="c-button">Je valide mon code</button>
                                        :
                                            <Loader/>
                                        }
                                    </div>
                                </div>
                            </form>
                            )
                        }
                            <form className="t-register_form c-form" onSubmit={handleSubmit(onSubmit)}
                                  autoComplete="off">
                                <input type="hidden" name="lba_referer" value={document.referrer != '' ? document.referrer : (window.historique_url && window.historique_url.length > 1 ? window.historique_url[window.historique_url.length - 2] : "")}
                                    ref={register({
                                        required: false
                                    })}  
                                />
                                <input type="hidden" name="lba_httpuseragent" value={navigator.userAgent}
                                    ref={register({
                                        required: false
                                    })}   
                                />
                                <input type="hidden" name="lba_numadherentprint" value={formNumAdherent} 
                                    ref={register({
                                        required: false
                                    })}    
                                />
                                <h2 id="form_civility" className="title-1 lowercase--">Civilité</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label>Civilité <sup>*</sup></label>
                                        <div className="c-form_radio">
                                            <div className="c-form_radioItem">
                                                <input type="radio" name="lba_civilite"
                                                    value="2"
                                                    id="lba_civiliteWoman"
                                                    ref={register({
                                                        required: true
                                                    })}
                                                />
                                                <label htmlFor="lba_civiliteWoman">Madame</label>
                                            </div>
                                            <div className="c-form_radioItem">
                                                <input type="radio" name="lba_civilite"
                                                    id="lba_civiliteMen"
                                                    value="1"
                                                    ref={register({
                                                        required: true
                                                    })}
                                                />
                                                <label htmlFor="lba_civiliteMen">Monsieur</label>
                                            </div>
                                        </div>
                                        <div
                                            className="error--">{errors.lba_civilite && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_fname">Prénom <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. Marie" name="lba_fname"
                                               id="lba_fname"
                                               ref={register({
                                                   required: 'Le champs doit être renseigné.',
                                                   minLength: {
                                                       value: 1,
                                                       message: 'Votre prénom doit contenir au moins 1 caractère'
                                                   },
                                                   maxLength: {
                                                       value: 50,
                                                       message: 'Votre prénom doit contenir au maximum 50 caractères'
                                                   },
                                                   pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                               })}
                                               data-error={!!(errors.lba_fname || existingAccount)}
                                               onChange={handleRemoveErrors}
                                               defaultValue={formDataCode?.lba_fname}
                                        />
                                        {(errors.lba_fname && errors.lba_fname.type === 'pattern') &&
                                        <div className="error--">Votre prénom doit être valide.</div>}
                                        {(errors.lba_fname && errors.lba_fname.type === 'required') &&
                                        <div className="error--">Le champs doit être renseigné.</div>}
                                        {(errors.lba_fname && errors.lba_fname.type === 'minLength') &&
                                        <div className="error--">Votre prénom doit contenir au moins 1 caractère.</div>}
                                        {(errors.lba_fname && errors.lba_fname.type === 'maxLength') &&
                                        <div className="error--">Votre prénom doit contenir au maximum 50 caractères.</div>}
                                        {/*<div*/}
                                            {/*className="error--">{errors.lba_fname && errors.lba_fname.message}</div>*/}
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_lname">Nom <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. Dupont" name="lba_lname"
                                            id="lba_lname"
                                            ref={register({
                                                required: 'Le champs doit être renseigné.',
                                                minLength: {
                                                    value: 1,
                                                    message: 'Votre nom doit contenir au moins 1 caractère'
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Votre nom doit contenir au maximum 50 caractères'
                                                },
                                                pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                            })}
                                            data-error={!!(errors.lba_lname || existingAccount)}
                                            onChange={handleRemoveErrors}
                                            defaultValue={formDataCode?.lba_lname}
                                        />
                                        {(errors.lba_lname && errors.lba_lname.type === 'pattern') &&
                                        <div className="error--">Votre nom doit être valide.</div>}
                                        {(errors.lba_lname && errors.lba_lname.type === 'required') &&
                                        <div className="error--">Le champs doit être renseigné.</div>}
                                        {(errors.lba_lname && errors.lba_lname.type === 'minLength') &&
                                        <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                                        {(errors.lba_lname && errors.lba_lname.type === 'maxLength') &&
                                        <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                                        {/*<div*/}
                                            {/*className="error--">{errors.lba_lname && errors.lba_lname.message}</div>*/}
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label>Date de naissance <sup>*</sup></label>
                                        <input type="text" placeholder="JJ/MM/AAAA" name="lba_birth"
                                               id="lba_birth"
                                               ref={register({
                                                   required: true,
                                                   maxLength: 10,
                                                   pattern: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
                                               })}
                                               data-error={(errors.lba_birth || birthError.message) ? true : false}
                                               onKeyUp={handleBirth}
                                               maxLength="10"
                                        />

                                        {(errors.lba_birth && errors.lba_birth.type === 'pattern') &&
                                        <div
                                            className="error--">Votre date de naissance doit respecter le format suivant : JJ/MM/AAAA.</div>}
                                        {(errors.lba_birth && errors.lba_birth.type === 'required') &&
                                        <div className="error--">Le champs doit être renseigné.</div>}
                                        {birthError &&
                                        <div className="error--">{birthError.message}</div>}
                                    </div>
                                </div>


                                <h2 id="form_coordonnees" className="title-1 lowercase--">Coordonnées</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt" data-error={errors.lba_countrycode ? true : false}>
                                        <label htmlFor="lba_countrycode">Pays <sup>*</sup></label>
                                        <Controller
                                            id="lba_countrycode"
                                            name="lba_countrycode"
                                            placeholder="Pays"
                                            as={Select}
                                            className="c-form_select lba_countrycode"
                                            classNamePrefix="c-form_select"
                                            options={options}
                                            control={control}
                                            defaultValue={formDataCode.lba_countrycode && formDataCode.lba_countrycode}
                                            rules={{ required: true }}
                                            // menuIsOpen="true"
                                        />
                                        <div
                                            className="error--">{errors.lba_countrycode && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt" ref={tooltipContainer}>
                                        <label htmlFor="lba_address">Adresse <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. 22 rue Carnot" name="lba_address"
                                               id="lba_address"
                                               ref={register({
                                                   required: true,
                                                   pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                               })}
                                               defaultValue={formDataCode.lba_address ? formDataCode.lba_address : adresseAutocomplete.name && adresseAutocomplete.name}
                                               data-error={(errors.lba_address || existingAccount) ? true : false}
                                               autoComplete="off"
                                               onChange={handleChangeAdresse}
                                               onFocus={handleChangeAdresse}
                                               onBlur={handleLeaveAddressInput}
                                        />
                                        {showTooltip.show && <Tooltip type="autocomplete"
                                                                      target={tooltipContainer.current}>{showTooltip.value}</Tooltip>}
                                        <div
                                            className="error--">{errors.lba_address && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_address2">Complément d’adresse</label>
                                        <input type="text" placeholder="" name="lba_address2"
                                            id="lba_address2"
                                            ref={register({
                                                required: false
                                            })}
                                            defaultValue={formDataCode.lba_address2}
                                        />
                                        <div className="error--"></div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_zip">Code postal <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. 75011" name="lba_zip"
                                               id="lba_zip"
                                               ref={register({
                                                   required: true,
                                                   pattern: /^(([0-8][0-9])|(9[0-9]))[0-9]{3}$/
                                               })}
                                               defaultValue={formDataCode.lba_zip ? formDataCode.lba_zip : adresseAutocomplete.postcode && adresseAutocomplete.postcode}
                                               onChange={e => setAdresseAutocomplete({postcode: e.target.value})}
                                               data-error={(errors.lba_zip || existingAccount) ? true : false}
                                        />
                                        <div
                                            className="error--">{errors.lba_zip && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_city">Ville <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. Paris" name="lba_city"
                                               id="lba_city"
                                               ref={register({
                                                   required: true,
                                                   pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                               })}
                                               defaultValue={formDataCode.lba_city ? formDataCode.lba_city : adresseAutocomplete.city && adresseAutocomplete.city}
                                               data-error={(errors.lba_city || existingAccount) ? true : false}
                                               onChange={e => setAdresseAutocomplete({city: e.target.value})}
                                        />
                                        <div
                                            className="error--">{errors.lba_city && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_mobilephone">Mobile</label>
                                        <input type="tel" placeholder="Ex. 0645628897" name="lba_mobilephone"
                                               id="lba_mobilephone"
                                               ref={register({
                                                   required: false,
                                                   minLength: 10,
                                                   maxLength: 10,
                                                   pattern: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
                                               })}
                                               data-error={errors.lba_mobilephone ? true : false}
                                               maxLength="10"
                                               defaultValue={formDataCode.lba_mobilephone}
                                        />
                                        {(errors.lba_mobilephone && (errors.lba_mobilephone.type === 'pattern' || errors.lba_mobilephone.type === 'minLength' || errors.lba_mobilephone.type === 'maxLength')) &&
                                        <div
                                            className="error--">Votre numéro doit être valide et comporter 10 chiffres.</div>}
                                        {(errors.lba_mobilephone && errors.lba_mobilephone.type === 'required') &&
                                        <div
                                            className="error--">Le champs doit être renseigné.</div>}
                                    </div>
                                </div>

                                <div className="c-form_line">
                                    <div
                                        className="error--">{existingAccount && "Un compte avec ce prénom, nom et adresse existe déjà."}</div>
                                </div>


                                <h2 id="form_username" className="title-1 lowercase--">Mes identifiants</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_email">Email <sup>*</sup></label>
                                        <input type="email" placeholder="Ex. dupont@gmail.com" name="lba_email"
                                               id="lba_email"
                                               ref={register({
                                                   required: true,
                                                   pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                               })}
                                               data-error={(errors.lba_email || existingEmail) ? true : false}
                                               onChange={handleRemoveErrors}
                                               defaultValue={formDataCode?.lba_email}
                                        />
                                        <div
                                            className="error--">{errors.lba_email && "Le champs doit être renseigné."}</div>
                                        <div className="error--">{existingEmail && "Cet e-mail existe déjà ou est invalide."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_lbalogin">Pseudo <sup>*</sup></label>
                                        <input type="text" placeholder="" name="lba_lbalogin"
                                               id="lba_lbalogin"
                                               ref={register({
                                                   required: 'Le champs doit être renseigné.',
                                                   minLength: {
                                                       value: 5,
                                                       message: 'Votre pseudo doit contenir au moins 5 caractères'
                                                   },
                                                   pattern: {
                                                    value: /^[^@]*[^\.]*$/i,
                                                    message: "Votre pseudo ne doit pas être une adresse email"
                                                  }
                                               })}
                                               defaultValue={formDataCode.lba_lbalogin}
                                               data-error={(errors.lba_lbalogin || existingName) ? true : false}
                                               onChange={handleRemoveErrors}
                                        />
                                        <div
                                            className="error--">{errors.lba_lbalogin && errors.lba_lbalogin.message}</div>
                                        <div
                                            className="error--">{existingName && "Ce nom d'utilisateur existe déjà."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_lbapassword">Mot de passe <sup>*</sup>
                                            <span className="c-form_tooltip2" ref={tooltipInfoRef}>
                                        <i className='icon-info'
                                           onMouseOver={handleMouseTooltip}
                                           onMouseOut={handleMouseTooltip}
                                           data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                           data-typeref="tooltipInfoRef"
                                           data-type="info"
                                        ></i>
                                    </span>
                                        </label>
                                        <div className="c-form_info">
                                            <input type={revealPassword ? 'text' : "password"}
                                                   placeholder="Mot de passe*"
                                                   name="lba_lbapassword"
                                                   id="lba_lbapassword"
                                                   ref={register({
                                                       required: 'Le champs doit être renseigné.',
                                                       minLength: {
                                                           value: 8,
                                                           message: 'Votre mot de passe doit contenir au moins 8 caractères'
                                                       },
                                                   })}
                                                   onChange={(e) => handlePwdChange(e)}
                                                   className="has-tooltip"
                                                   data-error={(errors.lba_lbapassword || invalidPwd) ? true : false}/>
                                            <div className="c-form_tooltip" ref={tooltipHelpRef}>
                                                <i className={revealPassword ? 'icon-eye-no' : "icon-eye"}
                                                   data-type="lba_lbapassword"
                                                   onClick={handleClickPassword}></i>
                                                <i className="icon-what"
                                                   onMouseOver={handleMouseTooltip}
                                                   onMouseOut={handleMouseTooltip}
                                                   data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                                   data-type="help"
                                                   data-typeref="tooltipHelpRef"
                                                ></i>
                                            </div>
                                        </div>
                                        <div
                                            className={`c-form_password ${password.indicator && 'level' + password.indicator + '--'} `}>
                                            <div className="progressBar"><span>{password.value}</span></div>
                                        </div>
                                        <div
                                            className="error--">{errors.lba_lbapassword && errors.lba_lbapassword.message}</div>
                                        <div
                                            className="error--">{invalidPwd && !errors.lba_lbapassword && "Votre mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre"}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label
                                            htmlFor="lba_lbapassword2">Confirmation du mot de passe <sup>*</sup></label>
                                        <div className="c-form_info">
                                            <input type={revealPasswordConfirm ? 'text' : "password"}
                                                   placeholder="Mot de passe*"
                                                   name="lba_lbapassword2"
                                                   id="lba_lbapassword2"
                                                   ref={register({
                                                       validate: value =>
                                                           value === newPassword.current
                                                   })}
                                                   className="has-tooltip"
                                                   data-error={errors.lba_lbapassword2 ? true : false}/>
                                            <div className="c-form_tooltip" ref={tooltipHelp2Ref}>
                                                <i className={revealPasswordConfirm ? 'icon-eye-no' : "icon-eye"}
                                                   data-type="lba_lbapassword2"
                                                   onClick={handleClickPassword}></i>
                                                <i className="icon-what"
                                                   onMouseOver={handleMouseTooltip}
                                                   onMouseOut={handleMouseTooltip}
                                                   data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                                   data-type="help"
                                                   data-typeref="tooltipHelp2Ref"
                                                ></i>
                                            </div>
                                        </div>


                                        <div
                                            className="error--">{errors.lba_lbapassword2 && "Les mots de passe ne correspondent pas"}</div>
                                    </div>
                                    {showTooltipInfo.show && <Tooltip type={showTooltipInfo.type}
                                                                      target={showTooltipInfo.ref}>{showTooltipInfo.content}</Tooltip>}
                                </div>

                                <h2 id="form_options" className="title-1 lowercase--">Mes avantages</h2>
                                <p>En vous inscrivant à La Belle Adresse, vous pouvez imprimer vos bons de réduction, découvrir et commenter nos articles, participer à des tests produits via le Club Ambassadeurs, recevoir nos newsletters et nos communications événementielles (actualité des marques, innovation, bons plans, ...)</p>
                                <div className="c-form_advantages">
                                    <div className="c-form_line">
                                        <div className="c-form_lineEltLarge">
                                            <div className="c-form_optin c-form_icon">
                                                <label
                                                    className="email--"><span>J’accepte de recevoir par email les  newsletters de La Belle Adresse (réductions, DIY, astuces, idées, innovations, ...).*</span></label>
                                                <div className="c-form_radio">
                                                    <div className="c-form_radioItem">
                                                        <input type="radio" name="lba_optinemail"
                                                               value="1"
                                                               id="registerOptinEmailYes"
                                                               ref={register({
                                                                   required: true
                                                               })}
                                                        />
                                                        <label htmlFor="registerOptinEmailYes">Oui</label>
                                                    </div>
                                                    <div className="c-form_radioItem"><input type="radio"
                                                                                             name="lba_optinemail"
                                                                                             id="registerOptinEmailNo"
                                                                                             value="0"
                                                                                             ref={register({
                                                                                                 required: true
                                                                                             })}
                                                    /><label htmlFor="registerOptinEmailNo">Non</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="error--">{errors.lba_optinemail && "Le champs doit être renseigné."}</div>
                                        </div>
                                    </div>
                                    <div className="c-form_line">
                                        <div className="c-form_lineEltLarge">
                                            <div className="c-form_optin c-form_icon">
                                                <label
                                                    className="mail--"><span>J’accepte de recevoir par courrier les messages de La Belle Adresse.*</span></label>
                                                <div className="c-form_radio">
                                                    <div className="c-form_radioItem">
                                                        <input type="radio" name="lba_optincourrier"
                                                               value="1"
                                                               id="registerOptinMailYes"
                                                               ref={register({
                                                                   required: true
                                                               })}
                                                        />
                                                        <label htmlFor="registerOptinMailYes">Oui</label>
                                                    </div>
                                                    <div className="c-form_radioItem"><input type="radio"
                                                                                             name="lba_optincourrier"
                                                                                             id="registerOptinMailNo"
                                                                                             value="0"
                                                                                             ref={register({
                                                                                                 required: true
                                                                                             })}
                                                    />
                                                        <label htmlFor="registerOptinMailNo">Non</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="error--">{errors.lba_optincourrier && "Le champs doit être renseigné."}</div>
                                        </div>
                                    </div>
                                    <div className="c-form_line">
                                        <div className="c-form_lineEltLarge">
                                            <div className="c-form_optin c-form_icon">
                                                <label
                                                    className="sms--"><span>J’accepte de recevoir par SMS les  informations de La Belle Adresse (promotions, innovation, information,...).*</span></label>
                                                <div className="c-form_radio">
                                                    <div className="c-form_radioItem">
                                                        <input type="radio" name="lba_optinsms"
                                                               value="1"
                                                               id="registerOptinSmsYes"
                                                               ref={register({
                                                                   required: true
                                                               })}
                                                        />
                                                        <label htmlFor="registerOptinSmsYes">Oui</label>
                                                    </div>
                                                    <div className="c-form_radioItem"><input type="radio"
                                                                                             name="lba_optinsms"
                                                                                             id="registerOptinSmsNo"
                                                                                             value="0"
                                                                                             ref={register({
                                                                                                 required: true
                                                                                             })}
                                                    />
                                                        <label htmlFor="registerOptinSmsNo">Non</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="error--">{errors.lba_optinsms && "Le champs doit être renseigné."}</div>
                                        </div>
                                    </div>
                                    <div className="c-form_line padding--">
                                        <div className="c-form_lineEltLarge">
                                            <div className="c-form_checkbox">
                                                <input type="checkbox" name="lba_conditions"
                                                       id="lba_conditions"
                                                       value="1"
                                                       ref={register({
                                                           required: true
                                                       })}
                                                />
                                                <label
                                                    htmlFor="lba_conditions">J’ai lu et j’accepte les conditions générales d’utilisation du site labelleadresse.com et j’accepte de bénéficier des avantages listés ci-dessus.*</label>
                                            </div>
                                            <div
                                                className="error--">{errors.lba_conditions && "Le champs doit être renseigné."}</div>
                                        </div>
                                    </div>
                                    <p className="mentions">Vous pouvez bien-sûr à tout moment modifier vos choix d’abonnement (newsletters, communications événementielles, tests produits) en vous connectant à votre Espace Perso ou en cliquant sur le lien de désabonnement présent à chaque communication. Si vous avez des questions, nous restons à votre entière disposition au 09 69 32 05 70 (appel non surtaxé) ou par email.</p>
                                </div>

                                <div className="c-form_recaptcha"
                                     data-error={(errors.g_recaptcha_response || emptyRecaptcha) ? 'true' : 'false'}>
                                    <Controller
                                        control={control}
                                        name="g_recaptcha_response"
                                        rules={{required: true}}
                                        render={() => (
                                            <ReCaptcha
                                                ref={captchaRef}
                                                sitekey={recaptchaKey}
                                                className="g-recaptcha"
                                                name="g_recaptcha_response"
                                                data-size="compact"
                                                onChange={val => {
                                                    //   console.log('recaptcha val', val);
                                                    setValue("g_recaptcha_response", val);
                                                    setRecaptchaVal(val);
                                                    setEmptyRecaptcha(false);
                                                }}
                                            />
                                        )}
                                    />
                                    <div
                                        className="error--">{(errors.g_recaptcha_response || emptyRecaptcha) && "Veuillez cocher la case."}</div>
                                </div>

                                <div className="c-form_line c-form_optin"></div>
                                {!loading ?
                                    <button type="submit" className="c-button">Valider</button>
                                    :
                                    <Loader/>
                                }
                                {(errorGlobal) &&
                                        <div className="error-- errormore--">Erreur générale : Veuillez contacter l'administrateur.</div>}
                                {(errorDupli) &&
                                        <div className="error-- errormore--">Vous êtes déjà membre, merci de vous connecter avec vos identifiants.</div>}
                                <p className="mentions">Conformément au Règlement 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (RGPD) et à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés dans sa version en vigueur, vous pouvez accéder à vos données, en solliciter la rectification ou l’effacement, ou la limitation de leur traitement. Vous avez également le droit de vous opposer à tout moment au traitement de vos données. Vous avez également la possibilité de définir des instructions sur le traitement de vos données après votre décès. Pour exercer ces droits, vous pouvez contacter par voie électronique à <a href="mailto:compliance.france@henkel.com">compliance.france@henkel.com</a> ou par voie postale à Service Consommateurs Henkel France, 245 rue du Vieux Pont de Sèvres, 92100 Boulogne-Billancourt.</p>
                            </form>
                        </>
                    }
                </div>
            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
}

export default TemplateRegister;