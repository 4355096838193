import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import normalize from "json-api-normalizer";
import build from "redux-object";

// Hooks
import {
    updateCartCashback,
    updateGlobalStorePage,
    updateGlobalCartStepsReset, updateModalsOpen
} from '../Store/action';
import useApi from "../Hooks/useApiGlobal";
import useTracking from "../Hooks/useTracking";
import useApiJeton from "../Hooks/useApiJeton";
import { useFileUpload } from "../Hooks/useFileUpload";

// Libraries
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";


// Components
import { Loader } from "../Components/loader";
import Metatags from "../Layout/metas-tags/meta-tags";
import Step0 from "../Components/cartStep0";
import Product from "../Components/product";
import Timeline from "../Components/timeline";
import CLink from "../Components/cLinks";
import Chatbot from "../Components/chatbot";

// Utils
import {decodeEntities} from "../Utils/decodeEntities";
import {md5, randomString} from "../Utils/md5-hash";
import {getMsgError} from '../Utils/getMsgError';
import {getCookie} from '../Utils/cookie';

/**
 * @typedef {Object} FileObject
 * @property {string} data
 * @property {string} type
 * @property {string} name
 */

// import qs from "qs";
// let IBAN = require('iban');
const ibantools = require('ibantools');

const TemplateCartCashback = ({ _uid }) => {
    const history = useHistory();
    let location = useLocation();
    let dispatch = useDispatch();

    // Page's classname
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-cart-cashback'));
    }, [dispatch]);

    // Get page's infos
    const [dataPageParams, setDataPageParams] = useState({});
    const [dataPage, setDataPage] = useState();

    const [dataFetch, isLoaded] = useApi(dataPageParams);

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataPage(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    // Get user's info if user exists
    const [userInfos, setUserInfos] = useState({});
    const [paramsUser, setParamsUser] = useState('');
    const [initClient, isLoadedUser] = useApi(paramsUser);
    let { uuid, iupid } = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;
    useEffect(() => {
        if (uuid) {
            setParamsUser({ name: `api/users/${uuid}`, method: 'get' });
            setDataPageParams({
                name: 'api/node/special',
                _uid: _uid,
                fields: '&fields[node--special]=metatag,field_special_configformref',
                method: 'get'
            });
            setAllProductsParams({
                name: 'api/lba-cashback/selection',
                method: 'get'
            });
        } else {
            dispatch(updateModalsOpen({ 'login': true }));

        }
    }, [_uid, dispatch, history, location.pathname,, uuid]);

    useEffect(()=>{
        if(!uuid && !modalsOpen.login) {
            history.replace({pathname: '/'});
        }
    },[ modalsOpen.login])

    useEffect(() => {
        if (initClient && isLoadedUser) {
            let { status, data } = initClient;
            //console.log('user', initClient);
            if (status === 200) {
                // console.log('user', data.data.attributes);
                let { lba_fname, lba_lname, lba_email, lba_address, lba_address2, lba_address3, lba_address4, lba_city, lba_zip} = data.data.attributes;

                let address = lba_address + ', ' + lba_zip + ' ' + lba_city;
                if (lba_address2) {
                    address = lba_address + ' ' + lba_address2 + ', ' + lba_zip + ' ' + lba_city
                }
                if (lba_address3) {
                    address = lba_address + ' ' + lba_address2 + ' ' + lba_address3 + ', ' + lba_zip + ' ' + lba_city
                }
                if (lba_address4) {
                    address = lba_address + ' ' + lba_address2 + ' ' + lba_address3 + ' ' + lba_address4 + ', ' + lba_zip + ' ' + lba_city
                }


                setUserInfos({
                    'name': lba_fname + ' ' + lba_lname,
                    'email': lba_email,
                    'address': address,
                });
            }
            else {
                console.log('error - get user\'s info');
            }
        }
    }, [initClient, isLoadedUser]);


    // Tracking
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const beginCheckoutTracking = (refresh) => {
        if (activeStep === 0) {//tracking uniquement sur l'etape 1
            setTrackingEvent({
                event: 'begin_checkout',
                args: {
                    membership_level: uuid ? 'member' : 'no-member',
                    currency: 'EUR',
                    ecommerce: {
                        currency: 'EUR',
                        items: itemsInitialTracking
                    }
                },
                refresh: refresh
            });
        }
    };

    const checkoutProgressTracking = (activeStepNumber, activeStepName, items, refresh) => {
        //  console.log(activeStepNumber, activeStepName);
        setTrackingEvent({
            event: 'checkout_progress',
            args: {
                membership_level: uuid ? 'member' : 'no-member',
                checkout_step_number: activeStepNumber,
                checkout_step_name: activeStepName,
                currency: 'EUR',
                ecommerce: {
                    currency: 'EUR',
                    items: items
                }
            },
            refresh: refresh
        });
    };

    const purchaseTracking = (amount, items, transaction, refresh) => {
        setTrackingEvent({
            event: 'purchase',
            args: {
                membership_level: uuid ? 'member' : 'no-member',
                currency: 'EUR',
                ecommerce: {
                    value: amount,
                    transaction_id: transaction,
                    currency: 'EUR',
                    items: items
                }
            },
            refresh: refresh
        });
    };

    const pageViewTracking = (category2, category3) => {
        setTrackingEvent({
            event: 'page_view',
            args: {
                content_category: 'Economies',
                content_category2: category2,
                content_category3: category3,
            }
        });
    };


    // Get cart from store
    let { cashback, cashbackError } = useSelector(state => state.cart);
    const [productsInitial, setProductsInitial] = useState([]);
    const [products, setProducts] = useState([]);

    const [errorGetCartProducts, setErrorGetCartProducts] = useState(null);
    useEffect(() => {
        // if (cashback && cashback.length > 0) {
        if (cashback) {
            // console.log('cashback', cashback);
            setProductsInitial(cashback);
        } else {
            setProductsInitial([]);
        }
        if (cashbackError) {
            setErrorGetCartProducts(cashbackError);
            setProductsInitial([]);
        }

        setAllProductsParams({
            name: 'api/lba-cashback/selection?' + randomString(6),
            method: 'get'
        });


    }, [cashback, cashbackError]);

    // Amount of offers
    const [offersAmount, setOffersAmount] = useState(0);
    const [hasPercent, setHasPercent] = useState(false);
    useEffect(() => {
        let discountAmountList = [];
        setHasPercent(false);
        if (products && products !== null) {
            // console.log('products', products);
            products.map(product => {
                discountAmountList.push(product.discount);
                if(product.discounttype === 'percent_off'){
                    setHasPercent(true);
                }
                return null;
            });
            // Sum of discountAmountList
            setOffersAmount(parseFloat(discountAmountList.reduce(function (acc, val) {
                return acc + val;
            }, 0)).toFixed(1));
        }
    }, [products]);


    // Steps of process
    const [activeStep, setActiveStep] = useState(0);
    const cartCashbackPath = useSelector(state => state.routes).routes.cartCashback;


    useEffect(() => {
        switch (activeStep) {
            case 0:
                history.push({ pathname: cartCashbackPath, search: '?step=0' });
                // checkoutProgressTracking(0, 'Panier', itemsInitialTracking, randomString(6));
                break;
            case 1:
                history.push({ pathname: cartCashbackPath, search: '?step=1' });
                pageViewTracking('Me faire rembourser', 'Preuve');
                setTimeout(() => {
                    checkoutProgressTracking(2, 'Preuve', itemsTracking, randomString(6));
                }, 50);
                break;
            case 2:
                history.push({ pathname: cartCashbackPath, search: '?step=2' });
                pageViewTracking('Me faire rembourser', 'Produits');
                setTimeout(() => {
                    checkoutProgressTracking(3, 'Produits', itemsTracking, randomString(6));
                }, 50);
                break;
            case 3:
                history.push({ pathname: cartCashbackPath, search: '?step=3' });
                pageViewTracking('Me faire rembourser', 'Remboursement');
                setTimeout(() => {
                    checkoutProgressTracking(4, 'Remboursement', itemsTracking, randomString(6));
                }, 50);
                break;
            case 4:
                history.push({ pathname: cartCashbackPath, search: '?step=4' });
                pageViewTracking('Me faire rembourser', 'Récapitulatif');
                setTimeout(() => {
                    checkoutProgressTracking(5, 'Récapitulatif', itemsTracking, randomString(6));
                }, 50);
                break;
            case 5:
                history.push({ pathname: cartCashbackPath, search: '?step=5' });
                // checkoutProgressTracking(5, 'Confirmation', itemsTracking, randomString(6));
                pageViewTracking('Me faire rembourser', 'Confirmation');
                break;
            default:
                break;
        }
    }, [activeStep]); // eslint-disable-line


    const [beginCheckout, setBeginCheckout] = useState(false);
    const [itemsTracking, setItemsTracking] = useState([]);
    useEffect(() => {
        // console.log('products selected', products);
        if (products && products.length > 0) {
            let items = [];
            products.map((item, key) => {
                items.push({
                    quantity: 1,
                    price: item.price || item.discount,
                    item_variant: 'cashback',
                    item_list_name: undefined,
                    item_list_id: undefined,
                    item_id: item.offerref,
                    item_category3: item.brandname,
                    item_category2: item.shelfname,
                    item_category: item.universename,
                    index: key,
                    currency: 'EUR'
                });
                return null;
            });
            setItemsTracking(items);
        }
    }, [products]);

    const [itemsInitialTracking, setItemsInitialTracking] = useState([]);
    useEffect(() => {
        if (productsInitial && productsInitial.length > 0) {
            let items = [];
            productsInitial.map((item, key) => {
                items.push({
                    quantity: 1,
                    price: item.price || item.discount,
                    item_variant: 'cashback',
                    item_list_name: undefined,
                    item_list_id: undefined,
                    item_id: item.offerref,
                    item_category3: item.brandname,
                    item_category2: item.shelfname,
                    item_category: item.universename,
                    index: key,
                    currency: 'EUR'
                });
                return null;
            });
            setBeginCheckout(false);
            setItemsInitialTracking(items);
        }
    }, [productsInitial, activeStep]); // eslint-disable-line

    useEffect(() => {
        if (itemsInitialTracking && itemsInitialTracking.length > 0 && !beginCheckout) {
            setBeginCheckout(true);
            beginCheckoutTracking(randomString(6));
            setItemsInitialTracking([]);
        }
    }, [itemsInitialTracking]); // eslint-disable-line

    // Handle browser back button to update active step
    let searchParams = location.search;
    const [locationKeys, setLocationKeys] = useState([]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)

                    // Handle forward event

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])

                    // Handle back event
                    if (searchParams !== '') {
                        let searchArray = searchParams.split('&');

                        searchArray.map((term, index) => {
                            let searchTerm = term.split('=');
                            let taxonomy = searchTerm[0];
                            let valeur = searchTerm[1];

                            if (index === 0) taxonomy = searchTerm[0].replace('?', '');

                            if (taxonomy === 'step') {
                                // on click on back button when on confirmation step = go to step 0
                                if (valeur === '5') {

                                    setActiveStep(0);
                                } else {
                                    setActiveStep(parseInt(valeur - 1));
                                }
                            }
                            return null;
                        });
                    }

                }
            }
        })
    }, [searchParams, locationKeys,]); // eslint-disable-line


    // Scroll top on step change
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeStep]);


    // Related Products
    const [relatedProducts, setRelatedProducts] = useState([]);
    // Related Products - Get all products
    const [allProductsParams, setAllProductsParams] = useState({});
    const [allProducts, isLoadedAllProducts] = useApi(allProductsParams);

    useEffect(() => {
        if (isLoadedAllProducts === true) {
            if (allProducts && allProducts.data && Array.isArray(allProducts.data) && allProducts.data.length > 0) {
                // console.log('allProducts', allProducts.data);
                const array = [];
                allProducts.data.map((product) => {
                    if (product.panier === false) {
                        array.push(product);
                    }
                    return null;
                });
                // Pick random elements
                let shuffled = array.sort(function () {
                    return .5 - Math.random()
                });
                let selected = shuffled.slice(0, 4);
                setRelatedProducts(selected);
            }
        }
    }, [isLoadedAllProducts]); // eslint-disable-line


    // Submit offers // end of cashback's process
    const [payment, setPayment] = useState();
    const [campagneId, setCampagneId] = useState({srcid : getCookie('srcid') ? parseInt(getCookie('srcid')) : ''});
    const [dataSubmitOffers, setDataSubmitOffers] = useState([]);
    const [dataSubmitOffersTracking, setDataSubmitOffersTracking] = useState([]);
    /* useEffect(() => {
         console.log('dataSubmitOffers', dataSubmitOffers);
     }, [dataSubmitOffers]);*/

    useEffect(() => {
        //  console.log('payment', payment);
        if (payment) {
            let offers = [];
            dataSubmitOffers.map(offer => {
                // console.log('offer', {...offer, ...payment, ...campagneId});
                offers.push({ ...offer, ...payment, ...campagneId });
                return null;
            });
            // console.log(offers);
            setDataSubmitOffers(offers);
        }
    }, [payment]);//eslint-disable-line

    const [errorSubmit, setErrorSubmit] = useState(false);

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);

    const offersSubmit = () => {
        console.log('offersSubmit');
        // console.log('dataSubmitOffers', dataSubmitOffers);

        // Récupération du Jeton
        setInitJeton({
            callback: sendOffers,
            params: dataSubmitOffers
        });
    };

    // Send Offers
    let [paramsSendOffers, setParamsSendOffers] = useState({});
    const [responseSendOffers, isLoadedSendOffers] = useApi(paramsSendOffers);

    const sendOffers = (token, datas) => {
        const headers = {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
        };

        //console.log('dataSubmitOffers', dataSubmitOffers);

        setParamsSendOffers({
            name: `api/lba-cashback/submit/send`,
            format: 'json',
            method: 'post',
            data: datas,
            config: headers,
            stringifyRequest: false // Body raw
        });
    };

    useEffect(() => {
        if (responseSendOffers && isLoadedSendOffers) {
            let { status, data } = responseSendOffers;
            //  console.log('submit offers response', responseSendOffers);
            if (status === 200) {
                setErrorSubmit(false);
                setActiveStep(activeStep + 1);

                // Empty basket only with offers submitted
                let newBasket = productsInitial;
                for (let i = newBasket.length - 1; i >= 0; i--) {
                    for (let j = 0; j < dataSubmitOffers.length; j++) {
                        if (newBasket[i] && (newBasket[i].offerref === dataSubmitOffers[j].offerref)) {
                            newBasket.splice(i, 1);
                        }
                    }
                }
                // console.log('productsInitial', productsInitial);
                // console.log('dataSubmitOffers', dataSubmitOffers);
                // console.log('newbasket', newBasket);
                dispatch(updateCartCashback(newBasket));


                /*
                let transactionID = 'undefined';
                if (data && data.participation_ids) {
                     transactionID = data.participation_ids[0];
                 }*/

                let transactionID = md5(iupid + Math.round(new Date().getTime() / 1000) + randomString(8));
                purchaseTracking(offersAmount, dataSubmitOffersTracking, transactionID, randomString(6));
            } else {
                // Error

                if (data.errors) {
                    //check if we have an error by other way than data.error_code
                    let firstErrors = data.errors[0];
                    let myRegexp = /.*\[CODE: ([^\]]+)]/g;
                    let codeError = myRegexp.exec(firstErrors);
                    // let codeError = firstErrors.match(/.*\[CODE: ([^\]]+)]/g)
                    if (codeError) {
                        setErrorSubmit(codeError[1]);
                        console.error("%cTemplateCartCashback.jsx -> 543 ERROR: ", 'background: #FF0000; color:#FFFFFF', codeError[0]);
                    }
                    else {
                        console.log('error - submit offers');
                        setErrorSubmit('Name or service not known');
                    }
                } else {

                    setErrorSubmit(data.error_code);
                    if (data && data.error_code) {
                        console.log('error - submit offers', data.error_code);
                    } else {
                        console.log('error - submit offers');
                    }
                }
            }
        }
    }, [responseSendOffers, isLoadedSendOffers]); // eslint-disable-line

    const deleteProduct = (offerref) => {
        //    console.log('deleteProduct CC');
        setProducts(products.filter(product => product.offerref !== offerref));
        // setProductsInitial(productsInitial.filter(product => product.offerref !== offerref));
    };


    const { cartStepsReset } = useSelector(state => state.global);

    useEffect(() => {
        if (cartStepsReset) {
            setActiveStep(0);
            setProducts([]);
            setItemsTracking([]);
            dispatch(updateGlobalCartStepsReset(false));
        }
    }, [cartStepsReset]); // eslint-disable-line


    const renderStep = (step, contentSteps) => {
        switch (step) {
            case 1:
                return <Step1 activeStep={activeStep} setActiveStep={setActiveStep} content={contentSteps[step - 1]}
                    setInitJeton={setInitJeton} />;
            case 2:
                return <Step2 activeStep={activeStep} setActiveStep={setActiveStep} products={products}
                    error={errorGetCartProducts}
                    content={contentSteps[step - 1]} dataSubmitOffers={dataSubmitOffers}
                    setDataSubmitOffers={setDataSubmitOffers}
                    dataSubmitOffersTracking={dataSubmitOffersTracking}
                    setDataSubmitOffersTracking={setDataSubmitOffersTracking}
                    deleteProduct={deleteProduct} />;
            case 3:
                return <Step3 activeStep={activeStep} setActiveStep={setActiveStep} user={userInfos}
                    setUser={setUserInfos} uuid={uuid} content={contentSteps[step - 1]}
                    setPayment={setPayment} setInitJeton={setInitJeton} />;
            case 4:
                return <Step4 activeStep={activeStep} setActiveStep={setActiveStep} products={products}
                    user={userInfos}
                    error={errorGetCartProducts}
                    offersAmount={offersAmount} content={contentSteps[step - 1]}
                    handleOffersSubmit={offersSubmit} errorSubmit={errorSubmit}
                    deleteProduct={deleteProduct} />;
            case 5:
                return <Step5 content={contentSteps[step - 1]} />;
            default:
                return null;
        }
    };

    const renderPage = () => {
        const { metatag, fieldSpecialConfigformref: { data: { steps } } } = dataPage;

        let pageviewEvent = {
            category: 'Economies',
            category2: 'Me faire rembourser',
            category3: 'Panier',
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                {activeStep > 0 ?
                    <div className="container container--mini">

                        {(activeStep === 1 && steps[0].title2) && <><h1 className="title-30">{steps[0].title2}</h1>
                            <div className="sep">&nbsp;</div>
                        </>}

                        <Timeline activeStep={activeStep} />

                        <div className="cartSteps">{renderStep(activeStep, steps)}</div>

                    </div>
                    :
                    <Step0 activeStep={activeStep} setActiveStep={setActiveStep} products={productsInitial}
                        offersAmount={offersAmount} error={errorGetCartProducts}
                        relatedProducts={relatedProducts}
                        type="refund" setProductsSelected={setProducts} productsSelected={products}
                        deleteProduct={deleteProduct} hasPercent={hasPercent}/>
                }
                <Chatbot />
            </>
        )
    };

    return dataPage ? renderPage() : <Loader />;
};


const Step1 = ({ activeStep, setActiveStep, content, setInitJeton }) => {
    let inputRef = useRef(null);
    const globalStore = useSelector(state => state.global);
    const baseURL = process.env.REACT_APP_APP;

    let [loaderCta, setLoaderCta] = useState(false);
    let [errorsStep, setErrorsStep] = useState({
        'step': false,
        'timeout': false,
        'token': false,
        'message': '',
        'maxReceipt': false,
    });

    const { readFile } = useFileUpload();

    /**@type {[FileObject[], React.Dispatch<React.SetStateAction<FileObject[]>>]} */
    const [files, setFiles] = useState([]);
    let [isErreurSize, setErreurSize] = useState(false);
    let [isLowConnexion, setLowConnexion] = useState(false);

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onFileUpload = async (event) => {
        const inputFiles = event.target.files;

        const SIZE_MAX = 10485760;
        let isSizeMax = false;

        inputFiles.forEach(file => file.size > SIZE_MAX && (isSizeMax = true))
        let trueFiles = Array.from(inputFiles).filter(file => file.size <= SIZE_MAX);

        setErreurSize(isSizeMax);

        if (!inputFiles.length || !trueFiles.length) return;

        /**@type {Promise<FileObject>[]} */
        const currentFiles = trueFiles.map(async (file) => {
            const data = await readFile(file);
            return {
                data,
                name: file.name,
                type: file.type,
            };
        })

        const values = await Promise.all(currentFiles);
        const { data, name, type } = values[0];
        // const size = (data.length * (3 / 4)) - 2;
        // console.log(size)
        const isExist = files.some((file) => file.data === data);

        if (!isExist && files.length <= globalStore.global.receipts_cardinality - 1) {

            setFiles((prevState) => ([
                ...prevState,
                {
                    data,
                    name,
                    type
                }
            ]));
        }
    }

    /**
     *
     * @param {string} data
     */
    const removeBuyingEvidence = (data) => {
        const updatedBuyingEvidences = files.filter((file) => file.data !== data);
        setFiles(updatedBuyingEvidences);
    }

    const handleClick = (e) => {
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    }

    const [isSlowConnection, setSlowConnection] = useState(false);
    // Form
    // Register form
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (data) => {
        //console.log('onSubmit', data);
        setLowConnexion(false);
        setLoaderCta(true);
        // Récupération du Jeton
        setInitJeton({
            callback: sendReceipt,
            success: function () {
                setErrorsStep({ ...errorsStep, 'token': false });
            },
            errors: function () {
                setLoaderCta(false);
                setErrorsStep({ ...errorsStep, 'token': true, 'message': "Erreur session token" });
            }
        });
    };

    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    // Send Receipt
    let [paramsSendReceipt, setParamsSendReceipt] = useState({});

    const [responseSendReceipt, isLoadedSendReceipt] = useApi(paramsSendReceipt);

    const sendReceipt = (sessionToken) => {
        const headers = {
            "X-CSRF-Token": sessionToken
        };

        const formData = new FormData();

        Array.from(files).forEach((file, index) => {
            const data = dataURLtoFile(file.data, file.name)
            formData.append(`file${Number(index + 1)}`, data);
        })

        setParamsSendReceipt({
            name: `api/lba-cashback/receipt`,
            format: 'json',
            method: 'post',
            data: formData,
            config: headers,
            stringifyRequest: false, // Body raw
            wantResponse: true
        });
    };

    useEffect(() => {
        if (files.length > globalStore.global.receipts_cardinality) {
            setErrorsStep({ ...errorsStep, maxReceipt: 'true' });
        }
    }, [errorsStep, files.length, globalStore.global.receipts_cardinality]);

    useEffect(() => {
        console.log("RESPONSE MAYBE",responseSendReceipt, isLoadedSendReceipt);

        if (responseSendReceipt && isLoadedSendReceipt) {
            let { status, data } = responseSendReceipt;
            setLoaderCta(false);
            if (status === 413) {
                setErrorsStep({ ...errorsStep, maxReceipt: 'true' });
            }
            if (status === 201) {
                setActiveStep(activeStep + 1);
                setErrorsStep({ ...errorsStep, 'step': false, 'timeout': false, 'message': '' });
            } else {
                // Error
                console.log('error - send receipt', data.error_code);
                if (responseSendReceipt === 'timeout') {
                    setErrorsStep({ ...errorsStep, 'timeout': true, 'message': data.error_code });
                } else {
                    setErrorsStep({ ...errorsStep, 'step': true, 'message': data.error_code });
                }
            }
        } else if(typeof(responseSendReceipt) === "undefined" && isLoadedSendReceipt){
            setLoaderCta(false);
            setLowConnexion(true);
        }
    }, [responseSendReceipt, isLoadedSendReceipt]); // eslint-disable-line

    return (
        <div className="cart-step1">
            {content.title && <h2 className="title-2">{content.title}</h2>}
            {content.title3 && <p><strong>{content.title3}</strong></p>}
            {content.description && <p dangerouslySetInnerHTML={{ __html: content.description }}></p>}

            <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="c-formContent">
                    <div className="c-form_file" style={{ paddingLeft: 0 }} data-imported={Object.entries(files).map((file) => file[0] !== undefined || file[1] !== undefined)}>
                        {files.length > 0 && (
                            <div className="buying-evidence-list">
                                {files.map((buyingEvidence, index) => {
                                    if (buyingEvidence.data) {
                                        return (
                                            <div key={buyingEvidence.data} className="buying-evidence">
                                                <div className="buying-evidence-content">
                                                    <div className="buying-evidence-name">
                                                        {buyingEvidence.name}
                                                    </div>
                                                    <button type="button" className="btn-2 buying-evidence-import"
                                                        data-error={errors.cashback_clue}
                                                        onClick={() => {
                                                            handleClick();
                                                            setTimeout(() => {
                                                                removeBuyingEvidence(buyingEvidence.data)
                                                            }, 1000)
                                                        }}
                                                    >
                                                        <i className="icon-photo" />
                                                        <span>{decodeEntities(content.ctaFile2)}</span>
                                                    </button>
                                                </div>
                                                <div className="c-product__delete buying-evidence-delete" onClick={() => removeBuyingEvidence(buyingEvidence.data)}>
                                                    <i className="icon-delete" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                         {
                        loaderCta && <>
                            <Loader />
                            <div className={`error-- error--step error--slowconnection`}>Connexion lente, fichier en cours d'import</div>
                        </>
                    }
                        {files.length === globalStore.global.receipts_cardinality ? (
                            null
                        ) : (
                                <>
                                    <button type="button" className="btn-2"
                                        data-error={errors.cashback_clue}
                                        onClick={handleClick}>
                                        <i className="icon-photo" />
                                        <span>{decodeEntities(content.ctaFile)}</span>
                                    </button>
                                    <input
                                        type="file"
                                        ref={inputRef}
                                        multiple
                                        onChange={onFileUpload}
                                        accept="image/*, application/pdf"
                                    // capture={isMobileOrTablet()}
                                    />
                                    {errors.cashback_clue && <div className="error--">Veuillez importer votre preuve d'achat</div>}
                                </>
                            )}
                    </div>

                    <div className="c-form_submit">
                        <button type="submit"
                            className="btn-2"
                            disabled=
                            {((loaderCta && !errorsStep.step && !errorsStep.token) || files.length === 0) && "disabled"}>{content.cta}</button>
                    </div>
                    {errorsStep.maxReceipt &&
                        <div className="error-- error--step">Une erreur est survenue : votre demande ne peut contenir que 3 photos maximum par demande de remboursement.</div>}
                    {(errorsStep.token || errorsStep.step) &&
                        <div className="error-- error--step">{errorsStep.message ? getMsgError(errorsStep.message) : "Une erreur est survenue"}</div>}
                    {(errorsStep.timeout) &&
                        <div className="error-- error--step">{errorsStep ? getMsgError(errorsStep.message) : "Une erreur est survenue"}</div>}
                    {(isErreurSize) &&
                        <div className="error-- error--step">La taille du fichier ne peut pas dépasser les 10 Mo.</div>}
                    {(isLowConnexion) &&
                        <div className="error-- error--step">Connexion lente, le fichier n'a pas pu être importé.</div>}


                </div>
            </form>
        </div>
    )
};

const Step2 = ({ activeStep, setActiveStep, content, error, products, dataSubmitOffers, setDataSubmitOffers, dataSubmitOffersTracking, setDataSubmitOffersTracking, deleteProduct }) => {
    // Form
    // Register form
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [items, setItems] = useState(dataSubmitOffers);
    const [itemsTracking, setItemsTracking] = useState(dataSubmitOffersTracking);

    const setItem = (offerref, id, barcode, discount, index, brandname, shelfname, universename) => {

        if (items.length > 0) {
            items.map(item => {
                if (item.offerref === offerref) {
                    const newArray = items;
                    const index = items.findIndex(element => element.offerref === offerref);
                    newArray[index].barcode = barcode;
                    setItems([...newArray]);
                } else {
                    setItems([...items, { "offerref": offerref, "barcode": barcode }]);
                }
                return null;
            });
        } else {
            setItems([...items, { "offerref": offerref, "barcode": barcode }]);
        }

        if (itemsTracking.length > 0) {
            itemsTracking.map(item => {
                if (item.offerref === offerref) {
                    const newArray = itemsTracking;
                    const index = itemsTracking.findIndex(element => element.offerref === offerref);
                    newArray[index].index = index;
                    newArray[index].item_id = id;
                    setItemsTracking([...newArray]);
                } else {
                    setItemsTracking([...itemsTracking, {
                        quantity: 1,
                        price: discount,
                        item_variant: 'cashback',
                        item_list_name: undefined,
                        item_list_id: undefined,
                        item_id: offerref,
                        item_index: index,
                        item_category3: brandname,
                        item_category2: shelfname,
                        item_category: universename,
                        index: index
                    }]);
                }
                return null;
            });
        } else {
            setItemsTracking([...itemsTracking, {
                quantity: 1,
                price: discount,
                item_variant: 'cashback',
                item_list_name: undefined,
                item_list_id: undefined,
                item_id: offerref,
                item_category3: brandname,
                item_category2: shelfname,
                item_category: universename,
                index: index
            }]);
        }
    };

    /*    useEffect(() => {
            console.log('items', items);
            console.log('itemsTracking', itemsTracking);
        }, [items, itemsTracking]);*/

    // Send products with no variables
    useEffect(() => {
        if (products) {
            const productsWithNoVariables = products.filter(product => product.products === null);
            //console.log(productsWithNoVariables);
            productsWithNoVariables.map(({ offerref, discount, index, brandname, shelfname, universename }) => {
                setItems([...items, { "offerref": offerref, "barcode": '' }]);
                setItemsTracking([...itemsTracking], {
                    quantity: 1,
                    price: discount,
                    item_variant: 'cashback',
                    item_list_name: undefined,
                    item_list_id: undefined,
                    item_id: offerref,
                    item_category3: brandname,
                    item_category2: shelfname,
                    item_category: universename,
                    index: index,
                });
                return null;
            });
        }
        if (products.length === 0) {
            setActiveStep(0);
        }
    }, [products]); // eslint-disable-line


    // Submit form
    const onSubmit = (data) => {
        //  console.log('onSubmit', data);
        setActiveStep(activeStep + 1);
        setDataSubmitOffers(items);
        setDataSubmitOffersTracking(itemsTracking);
    };

    return (
        <div className="cart-step2">
            {content.title && <h2 className="title-2">{content.title}</h2>}
            {content.description && <p>{content.description}</p>}
            {products ?
                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="c-formContent">
                        <p><strong>{products.length} {products.length > 1 ? 'Offres' : 'Offre'} à valider</strong></p>
                        <div className="cart__products">
                            <div className="cart__products-header">
                                <div><strong>Produit</strong></div>
                                <div><strong>Descriptif</strong></div>
                                <div><strong>Réduction</strong></div>
                            </div>
                            <ul className="cart__products-list">
                                {
                                    products.map((product, key) => {
                                        return (
                                            <ProductItem key={key} product={product}
                                                ref={register({ required: true })}
                                                step={activeStep}
                                                error={errors['cashback_offer_' + product.offerref]}
                                                setItem={setItem} index={key} deleteProduct={deleteProduct} />
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {content.cta &&
                            <div className="c-form_submit">
                                <button type="submit" className="btn-2">{content.cta}</button>
                            </div>
                        }
                    </div>
                </form>
                :
                <>{error ? <div className="">{getMsgError(error)}</div> : <Loader />}</>
            }
        </div>
    )
};

const ProductItem = React.forwardRef(({ step, product, error, setItem, index, deleteProduct }, forwardedRef) => {

    // Accordion state
    const [expanded, setExpand] = useState(false);

    // Colored state sticker
    const [updated, setUpdated] = useState(false);
    // Selected option
    const [option, selectedOption] = useState('');

    // Update product by choosing product variable
    const updateProduct = (e) => {
        setUpdated(true);
        selectedOption(e.target.value);
    };


    return (
        <li className="cart__products-list-item">
            <div className="itemProduct" data-error={typeof (error) !== 'undefined'}>
                <Product {...product} cart={true} index={index}
                    hasUpdate={step === 2}
                    updated={(!product.products || product.products.length === 0 || !Array.isArray(product.products)) ? true : updated}
                    type="refund" deleteProduct={deleteProduct} />
                {(step === 2 && product.products && Array.isArray(product.products)) &&
                    <div className="itemProduct__variable">
                        <div data-expanded={!expanded} data-initialexpanded="true">
                            <button type="button" className="itemProduct__variable-toggle" onClick={() => setExpand(true)}>
                                <p>Sélectionnez votre produit</p>
                                <div className="toggle__arrow">
                                    <i className="icon-arrow"></i>
                                </div>
                            </button>
                        </div>

                        <div data-expanded={expanded} data-initialexpanded="false">
                            <p><strong>Choisissez votre produit parmi la liste :</strong>
                            </p>
                            <div className="c-form c-form_line">
                                <div className="c-form_lineEltLarge">

                                    <div className="c-form_radio3">
                                        {product.products.map(({ description, id, barcode, price }, key) => {
                                            return (
                                                <div className="c-form_radio3Item"
                                                    key={key}>
                                                    <input type="radio"
                                                        value={id}
                                                        name={`cashback_offer_${product.offerref}`}
                                                        id={id}
                                                        onChange={e => {
                                                            updateProduct(e);
                                                            setItem(product.offerref, id, barcode, product.discount, key, product.brandname, product.shelfname, product.universename);
                                                        }}
                                                        defaultChecked={option === id}
                                                        ref={forwardedRef}
                                                    />
                                                    <label
                                                        htmlFor={id}>{decodeEntities(description)}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="itemProduct__variable-toggle"
                                onClick={() => setExpand(false)}>
                                <div className="toggle__arrow">
                                    <i className="icon-arrow" data-expanded="true"></i>
                                </div>
                            </button>
                        </div>
                    </div>
                }
            </div>
            {error &&
                <div className="error--">Veuillez sélectionner un produit.</div>}
        </li>
    )
});


const Step3 = ({ activeStep, setActiveStep, content, user, setUser, uuid, setPayment, setInitJeton }) => {
    // Form
    // Register form
    const { register, handleSubmit, errors, control } = useForm();

    const [loaderCta, setLoaderCta] = useState(false);
    const [errorsStep, setErrorsStep] = useState({
        'token': false,
        'step': false,
        'message': ''
    });

    const [errorIBAN, setErrorIBAN] = useState(false);
    const [errorBIC, setErrorBIC] = useState(false);


    // Check iban
    const [checkIbanParams, setCheckIbanParams] = useState({});
    const [checkIban, isLoadedCheckIban] = useApi(checkIbanParams);
    const [datasIban, setDatasIban] = useState({});

    const onSubmit = (data) => {
        setDatasIban(data);
        if (!ibantools.isValidIBAN(data.cashback_iban.replace(/\s+/g, ''))) {
            setErrorIBAN(true);
        }
        if (!ibantools.isValidBIC(data.cashback_bic)) {
            setErrorBIC(true);
        }

        if ((data.cashback_mode === 'virement' && ibantools.isValidIBAN(data.cashback_iban.replace(/\s+/g, '')) &&
            ibantools.isValidBIC(data.cashback_bic)) || data.cashback_mode === 'paypal') {
            setLoaderCta(true);
            setErrorIBAN(false);
            setErrorBIC(false);
            if (data.cashback_mode === 'virement') {
                setCheckIbanParams({
                    method: 'get',
                    name: `api/lba-cashback/iban-unicity?iban=`+data.cashback_iban.replace(/\s+/g, ''),
                })
            }
        }
    };

    useEffect(() => {
        if (isLoadedCheckIban && checkIban && datasIban) {
            console.log(datasIban)
            if(checkIban.status === 200) {

                setUser({
                    ...user, 'payment': 'virement', 'iban': datasIban.cashback_iban.replace(/\s+/g, ''), 'bic': datasIban.cashback_bic.toUpperCase()
                });
                setPayment({
                    "payment_type": 2,
                    "IBAN": datasIban.cashback_iban.replace(/\s+/g, ''),
                    "BIC": datasIban.cashback_bic.toUpperCase(),
                });

                setLoaderCta(false);
                setActiveStep(activeStep + 1);
            }
            else {
                setLoaderCta(false);
                setErrorsStep({ ...errorsStep, 'token': true, 'message': checkIban.data });
            }
        }
    }, [checkIban, isLoadedCheckIban, datasIban]); // e


    // Step 3  Select options
    const options = [
        { value: 'virement', label: content.virement },
        // { value: 'paypal', label: content.paypal }
    ];

    const [defaultValue, setDefaultValue] = useState(options[0]);

    // Step 3 Switch select
    const [cashbackMode, setCashbackMode] = useState('virement');

    useEffect(() => {
        if (user && user.payment) {
            // setCashbackMode(user.payment);
            // setDefaultValue(user.payment === 'paypal' ? options[1] : options[0]);
            setDefaultValue(options[0]);
        }
    }, [user]); // eslint-disable-line


    const [formattedIBAN, setFormattedIBAN] = useState('');

    const formatIBAN = (value) => {
        // Retire tous les espaces et convertit en majuscules
        let rawValue = value.replace(/\s+/g, '').toUpperCase();
        // let rawValue = value;
        // Ajoute un espace tous les 4 caractères
        return rawValue.replace(/(.{4})/g, '$1 ').trim();
        // return rawValue;


    };

    const handleIBANChange = (e) => {
        const inputIBAN = e.target.value;
        const formatted = formatIBAN(inputIBAN);
        setFormattedIBAN(formatted);
        ibantools.isValidIBAN(formatted.replace(/\s+/g, '')) ?  setErrorIBAN(false): setErrorIBAN(true);
    };


    const currentError = getMsgError()

    return (
        <div className="cart-step3">
            {content.title && <h2 className="title-2">{content.title}</h2>}
            <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="c-formContent">
                    {defaultValue &&
                        <div className="c-form c-form_line" style={{display:'none'}}>
                            <div className="c-form_lineElt">
                                <label htmlFor="cashback_mode">Mode de remboursement</label>
                                <Controller
                                    control={control}
                                    defaultValue={defaultValue.value}
                                    name="cashback_mode"
                                    render={({ onChange, value, name, ref }) => (
                                        <Select
                                            isSearchable={false}
                                            className="c-form_select"
                                            classNamePrefix="c-form_select"
                                            placeholder="Mode de remboursement"
                                            inputRef={ref}
                                            defaultValue={defaultValue}
                                            options={options}
                                            value={options.find(c => c.value === value)}
                                            onChange={val => {
                                                onChange(val.value);
                                                setCashbackMode(val.value);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    }
                    {cashbackMode === "virement" &&
                        <>
                            <p>Veuillez renseigner vos coordonnées bancaires</p>
                            <div className="c-form c-form_line">
                                <div className="c-form_lineElt c-form_lineElt--more iban-field">
                                    <input type="text" placeholder="IBAN" name="cashback_iban"
                                        id="cashback_iban"
                                        ref={register({
                                            required: true,
                                            // pattern: /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i
                                        })}
                                        value={formattedIBAN}
                                        onChange={handleIBANChange}
                                        defaultValue={(user && user.iban) ? user.iban : ''}
                                        data-error={errors.cashback_iban && errorIBAN}
                                    />
                                    {((errors.cashback_iban && errors.cashback_iban.type === 'pattern') || errorIBAN) &&
                                        <div className="error--">Votre IBAN doit être valide et de la zone euro.</div>}
                                    {(errors.cashback_iban && errors.cashback_iban.type === 'required') &&
                                        <div className="error--">Le champs doit être renseigné.</div>}

                                </div>
                            </div>
                            <div className="c-form c-form_line">
                                <div className="c-form_lineElt c-form_lineElt--more iban-field">
                                    <input type="text" placeholder="BIC" style={{textTransform: "uppercase"}} name="cashback_bic"
                                        id="cashback_bic"
                                        ref={register({
                                            required: true,
                                            pattern: /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g
                                        })}
                                        onChange={(e) => ibantools.isValidBIC(e.target.value) ? setErrorBIC(false) : setErrorBIC(true)}
                                        defaultValue={(user && user.bic) ? user.bic : ''}
                                        data-error={errors.cashback_bic && errorBIC}
                                    />
                                    {((errors.cashback_bic && errors.cashback_bic.type === 'pattern') || errorBIC) &&
                                        <div className="error--">Votre BIC doit être valide.</div>}
                                    {(errors.cashback_bic && errors.cashback_bic.type === 'required') &&
                                        <div className="error--">Le champs doit être renseigné.</div>}
                                </div>
                            </div>
                        </>
                    }
                    {cashbackMode === "paypal" &&
                        <div className="c-form_line">
                            <div className="c-form_lineElt">
                                <input type="email" placeholder="Ex. mariedupont@gmail.com" name="cashback_email"
                                    id="cashback_email"
                                    ref={register({
                                        required: true,
                                        maxLength: 50,
                                        //pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                    })}
                                    defaultValue={(user && user.paypal) ? user.paypal : ''}
                                    data-error={errors.cashback_email}
                                />
                                {(errors.cashback_email && errors.cashback_email.type === 'pattern') &&
                                    <div className="error--">Votre email doit être valide.</div>}
                                {(errors.cashback_email && errors.cashback_email.type === 'required') &&
                                    <div className="error--">Le champs doit être renseigné.</div>}
                                {(errors.cashback_email && errors.cashback_email.type === 'maxLength') &&
                                    <div className="error--">Une erreur est survenue : votre adresse email dépasse le nombre de caractères autorisés (50 caractères).</div>}
                            </div>
                        </div>
                    }
                    {content.cta &&
                        <div className="c-form_submit">
                            <button type="submit" className="btn-2"
                                disabled={(loaderCta && !errorsStep.step && !errorsStep.token) &&
                                    "disabled"}>{content.cta}</button>
                        </div>
                    }
                    {(errorsStep.token || errorsStep.step) &&
                        <div className="error-- error--step">{errorsStep.message ? getMsgError(errorsStep.message) : "Une erreur est survenue"}</div>}
                    {content.gdpr && content.gdpr.text &&
                        <div className="mentions" dangerouslySetInnerHTML={{ __html: content.gdpr.text }} />
                    }
                </div>
            </form>
        </div>
    )
};

const Step4 = ({ activeStep, setActiveStep, content, error, products, offersAmount, user, handleOffersSubmit, errorSubmit, deleteProduct }) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (products.length === 0) {
            setActiveStep(0);
        }
    }, [products]); // eslint-disable-line

    return (
        <div className="cart-step4">
            {content.title && <h2 className="title-2">{content.title}</h2>}
            {products ?
                <>
                    <div className="cart__products">
                        <p><strong>Votre demande de remboursement</strong></p>
                        <ul className="cart__products-list">
                            {
                                products.map((product, key) => {
                                    return (
                                        <ProductItem key={key} product={product} step={activeStep} index={key}
                                            deleteProduct={deleteProduct} />
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="cart__refurb">
                        <span>Total du remboursement : </span><span>{offersAmount.toString().replace('.', ',')}€</span>
                    </div>

                    {user &&
                        <div className="cart__personnalInfo">
                            <p><strong>Vos coordonnées</strong></p>
                            <div className="cart__personnalInfo-box">
                                {user.name && user.name}​ / {user.email && user.email}<br />
                                {user.address && user.address}<br />
                                {user.payment === 'virement' &&
                                    <>
                                        {user.iban && user.iban}<br />
                                        {user.bic && user.bic}
                                    </>
                                }
                                {user.payment === 'paypal' &&
                                    <>
                                        {user.paypal && user.paypal}
                                    </>
                                }
                            </div>
                        </div>
                    }

                    {content.cta &&
                        <div className="cart__button">
                            <button type="button" className="btn-2"
                                onClick={() => {
                                    handleOffersSubmit();
                                    setLoading(true);
                                }}
                                disabled={(loading && !errorSubmit) && "disabled"}>
                                {content.cta}
                            </button>
                        </div>
                    }
                    {errorSubmit && <div className="error-- error--step">{errorSubmit != '' ? getMsgError(errorSubmit) : 'Une erreur est survenue'}</div>}
                </>
                :
                <>{error ? <div className="">{getMsgError(error)}</div> : <Loader />}</>
            }
        </div>
    )
}
    ;

const Step5 = ({ content }) => {
    const accountPath = useSelector(state => state.routes).routes.account;
    const accountEconomyPath = useSelector(state => state.routes).routes.accountEconomy;

    const accountFinalPath = accountEconomyPath ? accountEconomyPath : accountPath;

    return (
        <div className="cart-step5">
            {content.title && <h2 className="title-2">{content.title}</h2>}
            {content.confirmationMessage && content.confirmationMessage.text &&
                <div dangerouslySetInnerHTML={{ __html: content.confirmationMessage.text }} />}
            {content.cta && <div className="cart__button">
                <CLink className="btn-2" url={accountFinalPath}
                    title={content.cta} />
            </div>
            }
        </div>
    )
};

export default TemplateCartCashback;