import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import {/*updateGlobalScrollLock, */updateModalsOpen } from "../../Store/action";
import useTracking from '../../Hooks/useTracking';

// Libraries
// import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import { randomString } from "../../Utils/md5-hash";

const Modal = ({ modalName, modalNameTracking, children, disableClosing }) => {

    let dispatch = useDispatch();
    const modalEl = useRef();
    const history = useHistory();
    const modalsOpen = useSelector(state => state.modals).openModal;
    const loginType = useSelector(state => state.modals).loginType;
    
    // Body scroll lock
    if (modalsOpen[modalName]) {
        // disableBodyScroll(modalEl.current, {reserveScrollBarGap: true});
        // console.log('open modal');
        console.log(modalName, modalsOpen[modalName])
        document.body.style.overflow = "hidden";
    } else {
        // enableBodyScroll(modalEl.current);
        document.body.style.overflow = "";
    }


    // TRACKING - Popin display
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const currentPage = useSelector(state => state.page).categories;

    useEffect(() => {
        if (modalsOpen[modalName]) {
            // Set tracking event - popin display
            setTrackingEvent({
                event: 'popin_display',
                args: {
                    popin_name: modalNameTracking,
                    content_category: currentPage.category,
                    content_category2: currentPage.category2,
                    content_category3: currentPage.category3,
                },
                refresh: randomString(6)
            });
        }
    }, [modalsOpen]); // eslint-disable-line

    const closeModal = () => {
        if (!disableClosing) {
            let newState = {
                ...modalsOpen,
                [modalName]: false,
                'hasError': false
            };
            dispatch(updateModalsOpen(newState));
            if(modalName === "pageviews" || modalName === "offerBasket" || modalsOpen.noRedirection){}
            else if (loginType === "account" || loginType === "login" || (loginType === "operation" && window.location.href.indexOf('/bienvenue') < 0) ) {
                history.replace({ pathname: '/' });
            }
        }
    };

    return (
        <>
            {modalsOpen[modalName] &&
                <div className={`c-modal fade c-modal_${modalName} ${modalsOpen[modalName] ? 'show--' : ''}`} tabIndex="-1"
                    role="dialog" ref={modalEl}>
                    <div
                        className={`c-modal_overlay fade-- ${modalsOpen[modalName] ? 'show--' : ''} ${disableClosing ? 'noClick--' : ''}`}
                        onClick={() => closeModal()}>&nbsp;</div>
                    <div className="c-modal_container" role="document">
                        <div className="c-modal_content">
                            {!disableClosing &&
                                <button type="button" className="c-modal_close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal()}><i className="icon-close"></i></button>
                            }
                            <div className="c-modal_body">{children}</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default Modal;
